<template>
  <tr>
    <td
      class="px-6 py-4 text-sm font-medium leading-5 text-gray-900 whitespace-no-wrap"
    >
    <p class="mt-2 flex items-center text-sm text-gray-500">
      <i class="fad fa-file-signature flex-shrink-0 mr-1.5 h-5 w-6 text-gray-400 text-xl"></i>
    <span class="truncate">{{ lease.address }} </span>
    </p> 
    </td>
    <td class="px-6 py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap">
      {{ lease.createDate }}
    </td>
    <td v-if="lease.status === 'Open'" class="px-6 py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap"> 
       <i class="fas fa-circle text-yellow-400" :title="lease.status"></i>
    </td>
    <td v-else-if="lease.status === 'In Progress'" class="px-6 py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap"> 
       <i class="fas fa-circle text-green-300" :title="lease.status"></i>
    </td>
    <td v-else-if="lease.status === 'Expired'" class="px-6 py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap"> 
       <i class="fas fa-circle text-red-400" :title="lease.status"></i>
    </td>
    <td
      class="px-6 py-4 text-sm font-medium leading-5 text-right whitespace-no-wrap"
    >
      <RouterLink
        :to="{ name: 'lease-edit', params: { id: lease.id } }"
        class="text-ha-blue-one hover:text-ha-blue-two"
      >
        <i class="fad fa-edit text-xl" title="Edit"></i>
      </RouterLink>
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "leaseTableItem",
  props: {
    lease: Object,
  },
});
</script>