<template>
  <table class="min-w-full divide-y divide-gray-200">
    <thead>
      <tr>
        <th
          class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
        >
          Address
        </th>
        <th
          class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
        >
          Created on
        </th>
        <th
          class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
        >
          Status
        </th>
       
        <th class="px-6 py-3 bg-gray-50"></th>
      </tr>
    </thead>
    <tbody class="bg-white divide-y divide-gray-200">
      <LeaseTableItem
        v-for="(lease, index) in leases"
        :key="index"
        :lease="lease"
      />
    </tbody>
  </table>

  <Pagination/>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LeaseTableItem from "./LeaseTableItem.vue";
import Pagination from "./Pagination.vue";

export default defineComponent({
  name: "LeaseTable",
  components: { LeaseTableItem, Pagination},
  props: {
    leases: Array,
  },
});
</script>