<template>
<Header :header="header" :route="route" :btn_Title="btn_Title" :btnVisible="btnVisible" />
  

  <div class="flex flex-col items-center p-6 lg:p-8">
    <div class="w-full max-w-screen-xl -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"> 
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">  
        <div
          class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg"
        >   
          <ContactTable :contacts="contacts" />
        </div>
      </div>
    </div>
  </div>

  <div class="fixed z-10 inset-0 " aria-labelledby="modal-title" role="dialog" aria-modal="true" hidden>
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div class="fixed inset-0 bg-gray-500  bg-opacity-75 transition-opacity-100" aria-hidden="true"></div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

    <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
    <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
      <div>
        <div class="flex justify-center p-6 lg:p-8">
          <form class="w-11/12" :v-on="handleFormSubmission"> 
            <div class="flex flex-col w-full">
              <div>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  "Create Lease" 
                </h3>
                <!-- <p class="mt-1 text-sm leading-5 text-gray-500">[message]</p> -->
              </div>

              <!-- FORM FIELDS START -->
                <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                  
                  <div class="sm:col-span-6">
                    <label
                      for="avatar"
                      class="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Profile Picture
                      <i class="fas fa-asterisk required" aria-required="true"></i>
                    </label>
                    <div class="mt-1 rounded-md shadow-sm">
                      <input
                        id="avatar"
                        type="file"
                        required     
                        class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm 
                        focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"
                      />
                    </div>
                  </div>
                  
                  <div class="sm:col-span-2">
                    <label
                      for="name"
                      class="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Name
                      <i class="fas fa-asterisk required" aria-required="true"></i>
                    </label>
                    <div class="mt-1 rounded-md shadow-sm">
                      <input
                        id="name"
                        type="text"
                        required  
                        onChange="" 
                        class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm 
                        focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"

                      />
                    </div>
                  </div>
                          
                  <div class="sm:col-span-2">

                    <label
                      for="email"
                      class="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Email
                      <i class="fas fa-asterisk required" aria-required="true"></i>
                    </label>  
                    <div class="mt-1 relative rounded-md shadow-sm">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <!-- Heroicon name: solid/mail -->
                        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                          <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                        </svg>
                      </div>
                      <input 
                        id="email"
                        type="text"
                        autoComplete="email"
                        required   
                          class="block w-full pl-10  border-gray-300 rounded-md shadow-sm 
                        focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm" placeholder="you@example.com">
                    </div>

                  </div>

                  <div class="sm:col-span-1">
                    <label
                      for="phoneNumber"
                      class="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Phone
                    </label>
                    <div class="mt-1 rounded-md shadow-sm">
                      <input
                        id="phoneNumber"
                        type="text"
                        autoComplete="tel"   
                        onChange="" 
                        class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm 
                        focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"
                      />
                    </div>
                  </div> 

                  <div class="sm:col-span-1">

                    <label
                      for="role"
                      class="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Role
                      <i class="fas fa-asterisk required" aria-required="true"></i>
                    </label>
                    <div class="mt-1 rounded-md shadow-sm">
                      <input
                        id="role"
                        type="text" 
                        required  
                        onChange="" 
                        class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm 
                        focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"
                      />
                    </div>
                  </div>
                   
                </div> 
              <!-- FORM FIELDS END -->
            </div> 
            <div class="pt-5 mt-8 border-t border-gray-200">
              <div class="flex justify-end">
                <span class="inline-flex rounded-md shadow-sm">
                  <a
                    class="px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out border
                    border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                    href="/properties"
                  >
                    Cancel
                  </a>
                </span>
                <span class="inline-flex ml-3 rounded-md shadow-sm">
                  <button
                    type="submit" 
                    class="inline-flex justify-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out
                    bg-ha-rose-zero border border-transparent rounded-md hover:bg-ha-rose-two focus:outline-none focus:border-ha-rose-zero 
                    focus:shadow-outline-ha-rose-one active:bg-ha-rose-zero"
                  >
                    Update
                  </button> 
                </span>
              </div>
            </div>
          </form>
        </div> 
      </div>
      <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
          Deactivate
        </button>
        <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm">
          Cancel
        </button>
      </div>
    </div>
  </div>
  </div>
</template>

<script lang="ts">

import { defineComponent, onMounted, reactive, toRefs } from "vue"; 
import ContactTable from "../components/ContactTable.vue"; 
import { getContacts } from "../utils/fakeApi";
import Header from "../components/Header.vue";

export default defineComponent({

  name: "ContactIndex",

  components: {
    ContactTable,
    Header,
  },

  setup() {
    const state = reactive({
      contacts: [],
      header: 'Contacts',
      route: 'contact-create',
      btn_Title: 'Add Contact',
      btnVisible: false, 

    });

    onMounted(() => {
      state.contacts = getContacts().map((contact, index) => {
        return Object.assign(contact, { id: index });
      });
    }); 

    return {
      ...toRefs(state),
    };
  },
});
</script>