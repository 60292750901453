<template>
  <table class="min-w-full divide-y divide-gray-200">
    <thead>
      <tr>
        <th
          class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
        >
          Address
        </th>
        <th
          class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
        >
          Property Type
        </th>
        <th
          class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
        >
          Income
        </th>
        <th
          class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
        >
          Expenses
        </th>
        <th class="px-6 py-3 bg-gray-50"></th>
      </tr>
    </thead>
    <tbody class="bg-white divide-y divide-gray-200">
      <PropertyTableItem
        v-for="(property, index) in properties"
        :key="index"
        :property="property"
      />
    </tbody>
  </table>

  <Pagination/>

</template>

<script lang="ts">
import { defineComponent } from "vue";
import PropertyTableItem from "./PropertyTableItem.vue";
import Pagination from "./Pagination.vue";

export default defineComponent({
  name: "PropertyTable",
  components: { PropertyTableItem, Pagination },
  props: {
    properties: Array,
  },
});
</script>