<template> 
 
  <div class="flex justify-between items-center cursor-pointer" @click="toggleActive = !toggleActive">

    <div class="w-16 h-8 flex items-center bg-gray-300 rounded-full p-1 ease-in-out" :class="{ 'bg-ha-rose-zero': toggleActive}">
      <div class="bg-white w-7 h-7 rounded-full shadow-md transform duration-300 ease-in-out" :class="{ 'translate-x-7': toggleActive,}"></div>
    </div>
    <span class="ml-3" id="annual-billing-label">
      <span v-if="toggleActive === true" class="text-sm font-medium text-gray-900">Switch to Owner</span> 
      <span v-else class="text-sm font-medium text-gray-900">Switch to Tenant</span> 
    </span>
  </div>

</template>
 

<script lang="ts">
import { defineComponent} from "vue";   

export default defineComponent({
  name: "Switch",

  components: { 
  },
  props: {
    toggleActive : Boolean,  
  },
  
  
});
</script>