<template>
  <Header :header="header" :btnVisible="btnVisible" />

  <!-- <div class="flex justify-center p-6 lg:p-8 dark:bg-gray-900"> -->
  <div class="flex justify-around p-6 lg:p-8 dark:bg-gray-900">
    <div class="w-screen">
      <div class="grid lg:grid-cols-12 grid-cols-6 gap-4">
        
        <div class="col-span-12 sm:col-span-6 md:col-span-3">
          <div class="flex flex-row bg-white shadow-md rounded p-4">
            <div
              class="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-green-100 text-green-500"
            >
              <i class="text-lg fad fa-file-contract"></i>
            </div>
            <div class="flex flex-col flex-grow ml-4">
              <div class="text-sm text-gray-500 font-semibold">Leases</div>
              <div class="font-bold text-lg">230</div>
            </div>
          </div>
        </div>

        <div class="col-span-12 sm:col-span-6 md:col-span-3">
          <div class="flex flex-row bg-white shadow-md rounded p-4">
            <div
              class="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-yellow-100 text-yellow-500"
            >
              <i class="text-lg fad fa-city"></i>
            </div>
            <div class="flex flex-col flex-grow ml-4">
              <div class="text-sm text-gray-500 font-semibold">Properties</div>
              <div class="font-bold text-lg">190</div>
            </div>
          </div>
        </div>

        <div class="col-span-12 sm:col-span-6 md:col-span-3">
          <div class="flex flex-row bg-white shadow-md rounded p-4">
            <div
              class="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-blue-100 text-blue-500"
            >
              <i class="text-lg fad fa-house-user"></i>
            </div>
            <div class="flex flex-col flex-grow ml-4">
              <div class="text-sm text-gray-500 font-semibold">Tenants</div>
              <div class="font-bold text-lg">1259</div>
            </div>
          </div>
        </div>

        <div class="col-span-12 sm:col-span-6 md:col-span-3">
          <div class="flex flex-row bg-white shadow-md rounded p-4">
            <div
              class="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-red-100 text-red-500"
            >
              <i class="text-lg fad fa-toolbox"></i>
            </div>
            <div class="flex flex-col flex-grow ml-4">
              <div class="text-sm text-gray-500 font-semibold">Maintenance</div>
              <div class="font-bold text-lg">32</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> 
  
  <div class="grid lg:grid-cols-12 grid-cols-6 dark:bg-gray-900">

    <div class="col-span-6 px-4 sm:px-8 ">
      <WidgetsFanancialTable :latestTransactions="latestTransactions" />
    </div>
  
    <div class="col-span-6 px-4 sm:px-8">
      <WidgetsMaintenanceTable/>
    </div>

  </div>

  
</template>
 
<script lang="ts">
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import WidgetsFanancialTable from "../components/WidgetsFanancialTable.vue";
import WidgetsMaintenanceTable from "../components/WidgetsMaintenanceTable.vue";
import Header from "../components/Header.vue"; 
import { getLatestTransactions } from "../utils/fakeApi"; 

export default defineComponent({
  name: "Dashboard",

  components: {
    Header,
    WidgetsFanancialTable,
    WidgetsMaintenanceTable,
  },

  setup() {
    const state = reactive({
      header: "Dashboard",
      btnVisible: false,
      latestTransactions: []

    });

    onMounted(() => {
      
      state.latestTransactions = getLatestTransactions().map((latestTransaction, index) => {
          return Object.assign(latestTransaction, { id: index });
      });

    });

    return {
      ...toRefs(state),
    };
  },
});
</script>