<template>

<div class="w-screen hidden">

    <div class="grid lg:grid-cols-12 gap-4"> 

        <div class="col-span-12 sm:col-span-6 md:col-span-3">  
            <div class="rounded-lg shadow-sm mb-4">
                <div class="rounded-lg bg-white shadow-lg md:shadow-xl relative overflow-hidden">
                    <div class="px-3 pt-8 pb-10 text-center relative z-10">
                        <h4 class="text-sm uppercase text-gray-500 leading-tight font-semibold">Earnings</h4>
                        <h3 class="text-3xl text-gray-700 font-semibold leading-tight my-3">$3,800,682</h3>
                        <p class="text-xs text-green-500 leading-tight">▲ 57.1%</p>
                    </div> 
                <div class="absolute bottom-0 inset-x-0">
                        <canvas id="chart_earnings" class="sm:h-9" role="img"></canvas>
                    </div>
                </div>
            </div> 
        </div>

        <div class="col-span-12 sm:col-span-6 md:col-span-3">  
            <div class="rounded-lg shadow-sm mb-4">
                <div class="rounded-lg bg-white shadow-lg md:shadow-xl relative overflow-hidden">
                    <div class="px-3 pt-8 pb-10 text-center relative z-10">
                        <h4 class="text-sm uppercase text-gray-500 leading-tight font-semibold">Expenses</h4>
                        <h3 class="text-3xl text-gray-700 font-semibold leading-tight my-3">$427,778</h3>
                        <p class="text-xs text-red-500 leading-tight">▼ 42.8%</p>
                    </div>
                    <div class="absolute bottom-0 inset-x-0">
                        <canvas id="chart_expenses" class="sm:h-9" role="img"></canvas>
                    </div>
                </div>
            </div> 
        </div> 

        <div class="col-span-12 sm:col-span-6 md:col-span-3" hidden> 
            <div class="rounded-lg shadow-sm mb-4 ">
                <div class="rounded-lg bg-white shadow-lg md:shadow-xl relative overflow-hidden">
                    <div class="px-3 pt-8 text-center relative z-10 bottom-0">
                        <h4 class="text-sm uppercase text-gray-500 leading-tight font-semibold">Maintenance</h4> 
                    </div>
                    <div class="p-4">
                        <canvas id="chart4" role="img" ></canvas>
                    </div>     
                </div>
            </div>
        </div>


    </div> 
     
</div>  
 
<div class="w-full">
    <div class="grid lg:grid-cols-12 gap-4">
    
        <div class="bg-white shadow overflow-hidden sm:rounded-md col-span-12"> 
            
            <div class="flex justify-around p-3 lg:p-4 dark:bg-gray-900">
                <div class="flex-1 min-w-0">
                    <h1 class="text-lg font-medium leading-6 text-gray-900 sm:truncate">
                        Latest transactions
                    </h1>
                </div>

                <div class="flex-2 min-w-0 hidden">
                    <a href="/maintenance/create" class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white
                     bg-ha-rose-zero hover:bg-ha-rose-one focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" title="add new payment">
                    <!-- Heroicon name: solid/plus -->
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                    </svg>
                    </a>
                </div>

                <div class="flex-2 min-w-0">
                    <div class="relative inline-block ">
                        <div>
                            <button type="button" @click="toggleShowModal()" @blur="showModal = false" class="rounded-full flex items-center text-ha-rose-zero hover:text-ha-rose-two
                            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-ha-rose-zero" id="menu-button" aria-expanded="true" aria-haspopup="true">
                            <span class="sr-only">Open options</span> 
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                            </svg>
                            </button>
                        </div>
                        <WidgetsContextMenu :lists="contextMenuItems" :showModal="showModal" />
                    </div>
 
                </div>
            </div>    

            <div>
                <ul class="divide-y divide-gray-200">
                    <WidgetsFanancialItems 
                    v-for="(latestTransaction, index) in latestTransactions"
                    :key="index"
                    :latestTransactions="latestTransaction"
                    />
                </ul>

            <div class="flex justify-center p-2">
                <a href="/financials" class="block text-ha-blue-one hover:text-ha-blue-two">
                   <div class="min-w-0 px-4  flex md:gap-4">
                       <p class="text-sm font-medium  truncate ">See more </p> 
                    <p><i class="fad fa-angle-right text-ha-blue-one hover:text-ha-blue-two text-xl"></i></p>
                   </div>
                     
                </a> 
            </div>
            </div>
        </div> 

    </div>
    
</div> 


</template>
 
<script lang="ts">

import { defineComponent, reactive, toRefs, onMounted } from "vue";
import Chart from 'chart.js'; 
import WidgetsContextMenu from './WidgetsContextMenu.vue';
import WidgetsFanancialItems from './WidgetsFanancialItems.vue';

export default defineComponent({
    name: "WidgetsFanancialTable",  
    components: { 
        WidgetsContextMenu,
        WidgetsFanancialItems 
    },

    props:{ 
        latestTransactions: []
    },
    
    setup() {  

        const state = reactive({
            contextMenuItems: [
                [
                    { title: "Add income", link: "/income/create", subItems: [] },
                    { title: "Add expense", link: "/expense/create", subItems: [] }, 
                ],
            ],
            showModal: false

        });

        // var createMaintenance = (json) => {
        //     let labels = [];
        //     let values = [];

        //     json.forEach((value, key) => {
        //         labels.push(value.dimensionOne);
        //         values.push(value.quantity);
        //     });

        //     let data = {
        //         labels: labels,
        //         datasets: [{
        //             label: "Maintenance chart",
        //             backgroundColor: [
        //                 "rgb(245 158 11 / 70%)",
        //                 "rgba(16 185 129 / 70%)",
        //                 "rgba(239 68 68 / 70%)"
        //             ],
        //             borderColor: [                        
        //                  "rgba(254, 243, 199)",
        //                 "rgba(209, 250, 229)",
        //                 "rgba(254, 226, 226)"
        //             ], 
        //             borderAlign: true,
        //             borderWidth: 1,
        //             data: values, 
        //         }]
        //     };
 
        //     var options = {
        //         responsive: true,
        //         legend: {
        //             labels:{
        //                 fontSize: 12,

        //             },
        //             position: 'top',
        //         },
        //         scales: {
                     
        //         },
        //         tooltips: {
        //             mode: 'point',
        //             // callbacks: {
        //             //     label: function (tooltipItem, chart) {
        //             //         let value = chart.datasets[0].data[tooltipItem.index];
        //             //         var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
        //             //         return datasetLabel + ': ' +  value;
        //             //     }
        //             // }
        //         }, 
        //         title: {
        //             display: false,
        //             text: 'Maintenance'
        //         },
        //         animation: {
        //             animateScale: true,
        //             animateRotate: true
        //         }
        //     };

        //     var ctx = document.getElementById('chart3');
        //     let mychart  = new Chart(ctx, {
        //         options: options,
        //         data: data,
        //         type: 'doughnut'
        //     });

        // };

        const chartOptions = {
            maintainAspectRatio: false,
            legend: {
                display: false,
            },
            tooltips: {
                enabled: false,
            },
            elements: {
                point: {
                    radius: 0
                },
            },
            scales: {
                xAxes: [{
                    gridLines: false,
                    scaleLabel: false,
                    ticks: {
                        display: false
                    }
                }],
                yAxes: [{
                    gridLines: false,
                    scaleLabel: false,
                    ticks: {
                        display: false,
                        suggestedMin: 0,
                        suggestedMax: 10
                    }
                }]
            }
        };

        onMounted(() => {
                   
            var ctx = document.getElementById('chart_earnings');
            var chart = new Chart(ctx, {
                type: "line",
                data: {
                    labels: [1, 2, 1, 3, 5, 4, 7],
                    datasets: [
                        {
                            backgroundColor: "rgba(209, 250, 229)",
                            borderColor: "rgba(16, 185, 129)",
                            borderWidth: 2,
                            data: [1, 2, 1, 3, 5, 4, 7],
                        },
                    ],
                },
                options: chartOptions
            });
        
            var ctx = document.getElementById('chart_expenses');
            var chart = new Chart(ctx, {
                type: "line",
                data: {
                    labels: [2, 3, 2, 9, 7, 7, 4],
                    datasets: [
                        {
                            backgroundColor: "rgba(246, 109, 155, 0.1)",
                            borderColor: "rgba(246, 109, 155, 0.8)",
                            borderWidth: 2,
                            data: [2, 3, 2, 9, 7, 7, 4],
                        },
                    ],
                },
                options: chartOptions
            });
        
        });

        function toggleShowModal() {
            state.showModal = !state.showModal;
        }

        return {
        ...toRefs(state),  
        toggleShowModal,
        };
            
    },
    
});


</script>