<template>
<Header :header="header" :route="route" :btn_Title="btn_Title" :btnVisible="btnVisible" />


<div class="flex flex-col items-center p-6 -mb-6  lg:pl-16">
  <div class="w-full max-w-screen-xl overflow-x-auto sm:-mx-6 lg:-mx-10" >
    <div class="">
      <div @click="toggleActive = !toggleActive" class="flex items-center">
        <Switch :toggleActive="toggleActive" />
      </div>
    </div> 
  </div> 
</div>
 
<div class="flex flex-col items-center p-6 lg:p-8">

  <div class="w-full max-w-screen-xl -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      
    <div v-if="toggleActive === true" class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">

      <div>
        <h3 class="text-lg font-medium leading-10 text-gray-900 hidden">
          Tenants Maintenance
        </h3>
        <!-- <p class="mt-1 text-sm leading-5 text-gray-500">[message]</p> -->
      </div>

      <div class="overflow-hidden shadow sm:rounded-lg">
        <TenantMaintenanceTable :maintenances="tenantMaintenances" />
      </div>
    </div>

    <div v-else class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"> 
      <div>
        <h3 class="text-lg font-medium leading-10 text-gray-900 hidden">
          My Maintenance
        </h3>
        <!-- <p class="mt-1 text-sm leading-5 text-gray-500">[message]</p> -->
      </div>

      <div class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
        <MaintenanceTable :maintenances="myMaintenances" />
      </div>
    </div>

  </div>
</div>
</template>

<script lang="ts">
 
import { defineComponent, onMounted, reactive, toRefs } from "vue"; 
import MaintenanceTable from "../components/MaintenanceTable.vue"; 
import TenantMaintenanceTable from "../components/TenantMaintenanceTable.vue"; 
import { getMyMaintenances } from "../utils/fakeApi";
import { getTenantMaintenances } from "../utils/fakeApi";
import Header from "../components/Header.vue";
import Switch from "../components/Switch.vue";

export default defineComponent({
  name: "MaintenanceIndex",

  components: {
    MaintenanceTable,
    TenantMaintenanceTable,
    Header,
    Switch

  }, 

  setup(){

    const state = reactive({
      myMaintenances: [],
      tenantMaintenances: [],
      header: 'Maintenance',
      route: 'maintenance-create',
      btn_Title: 'Create Maintenance',
      btnVisible: true,
      toggleActive: false,
    });
 
    onMounted(() => { 

      state.myMaintenances = getMyMaintenances().map((myMaintenances, index) => {
        return Object.assign(myMaintenances, { id: index });
      }); 

      state.tenantMaintenances = getTenantMaintenances().map((tenantMaintenances, index) => {
        return Object.assign(tenantMaintenances, { id : index });
      });


    });

    return {
      ...toRefs(state), 
    };
  },

});
 
</script>