<template>
  <tr>
    <td
      class="px-6 py-4 text-sm font-medium leading-5 text-gray-900 whitespace-no-wrap truncate"
    >
    <p class="mt-2 flex items-center text-sm text-gray-500">
      <i class="fad fa-house flex-shrink-0 mr-1.5 h-5 w-6 text-gray-400 text-xl"></i>
      <span class="truncate">{{ property.address }} </span>
    </p>
    </td>

    <td v-if="property.type === 'Residential'" class="px-6 py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap">  
        <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-400">
          <svg class="mr-1.5 h-2 w-2 text-red-400" fill="currentColor" viewBox="0 0 8 8">
            <circle cx="4" cy="4" r="3" />
          </svg>
          {{ property.type }}
        </span>
    </td>
    <td v-else-if="property.type === 'Comercial'" class="px-6 py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap">  
        <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-400">
          <svg class="mr-1.5 h-2 w-2 text-yellow-400" fill="currentColor" viewBox="0 0 8 8">
            <circle cx="4" cy="4" r="3" />
          </svg>
          {{ property.type }}
        </span>
    </td>
    <td v-else-if="property.type === 'Student Housing'" class="px-6 py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap">   
        <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-400">
          <svg class="mr-1.5 h-2 w-2 text-blue-400" fill="currentColor" viewBox="0 0 8 8">
            <circle cx="4" cy="4" r="3" />
          </svg>
          {{ property.type }}
        </span>      
    </td>
     <td v-else class="px-6 py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap">    
        <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-400">
          <svg class="mr-1.5 h-2 w-2 text-green-400" fill="currentColor" viewBox="0 0 8 8">
            <circle cx="4" cy="4" r="3" />
          </svg>
          {{ property.type }}
        </span>

    </td>
     
    <td class="px-6 py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap">
      <span class="text-green-400">{{ property.income }}</span>
    </td>
    <td class="px-6 py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap">
      <span class="text-red-400">{{ property.expenses }}</span>
    </td>
    <td
      class="px-6 py-4 text-sm font-medium leading-5 text-right whitespace-no-wrap"
    >
      <RouterLink
        :to="{ name: 'property-edit', params: { id: property.id } }"
        class="text-ha-blue-one hover:text-ha-blue-two"
      >
        <i class="fad fa-edit text-xl" title="Edit"></i>
      </RouterLink>
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "PropertyTableItem",
  props: {
    property: Object,
  },
});
</script>