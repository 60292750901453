<template>
  <div class="w-screen hidden">
    <div class="grid lg:grid-cols-12 gap-4">
      <div class="col-span-12 sm:col-span-6 md:col-span-3">
        <div class="rounded-lg shadow-sm mb-4">
          <div
            class="rounded-lg bg-white shadow-lg md:shadow-xl relative overflow-hidden"
          >
            <div class="px-3 pt-8 pb-12 text-center relative z-10">
              <h4
                class="text-sm uppercase text-gray-500 leading-tight font-semibold"
              >
                New
              </h4>
              <h3
                class="text-3xl text-gray-700 font-semibold leading-tight my-3"
              >
                75
              </h3>
              <p class="text-xs text-yellow-500 leading-tight hidden">
                ▲ 57.1%
              </p>
            </div>
            <div class="absolute bottom-0 inset-x-0">
              <canvas id="chart_new" height="70" role="img"></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-span-12 sm:col-span-6 md:col-span-3">
        <div class="rounded-lg shadow-sm mb-4">
          <div
            class="rounded-lg bg-white shadow-lg md:shadow-xl relative overflow-hidden"
          >
            <div class="px-3 pt-8 pb-12 text-center relative z-10">
              <h4
                class="text-sm uppercase text-gray-500 leading-tight font-semibold"
              >
                In Progress
              </h4>
              <h3
                class="text-3xl text-gray-700 font-semibold leading-tight my-3"
              >
                200
              </h3>
              <p class="text-xs text-red-500 leading-tight hidden">▼ 42.8%</p>
            </div>
            <div class="absolute bottom-0 inset-x-0">
              <canvas id="chart_inProgress" height="70" role="img"></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-span-12 sm:col-span-6 md:col-span-3">
        <div class="rounded-lg shadow-sm mb-4">
          <div
            class="rounded-lg bg-white shadow-lg md:shadow-xl relative overflow-hidden"
          >
            <div class="px-3 pt-8 pb-12 text-center relative z-10">
              <h4
                class="text-sm uppercase text-gray-500 leading-tight font-semibold"
              >
                Resolved
              </h4>
              <h3
                class="text-3xl text-gray-700 font-semibold leading-tight my-3"
              >
                50
              </h3>
              <p class="text-xs text-red-500 leading-tight hidden">▼ 42.8%</p>
            </div>
            <div class="absolute bottom-0 inset-x-0">
              <canvas id="chart_resolved" height="70" role="img"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-full">
    <div class="grid lg:grid-cols-12 gap-4">
     
      <div class="bg-white shadow overflow-hidden sm:rounded-md col-span-12"> 
          
          <div class="flex justify-around p-3 lg:p-4 dark:bg-gray-900">
              <div class="flex-1 min-w-0">
                <h1 class="text-lg font-medium leading-6 text-gray-900 sm:truncate">
                    Maintenances
                </h1>
              </div>

              <div class="flex-2 min-w-0">
                  <a href="/maintenance/create" class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white
                    bg-ha-rose-zero hover:bg-ha-rose-one focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ha-rose-zero" title="add new payment"> 
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                  </svg>
                  </a>
              </div>

          </div>

          <ul class="divide-y divide-gray-200">
              <li>
                <a href="#" class="block hover:bg-gray-50">
                    <div class="flex items-center px-4 py-4 sm:px-6">
                    <div class="min-w-0 flex-1 flex items-center">
                        <div class="flex-shrink-0">
                        <img class="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixqx=qxgTj0gLwD&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">
                        </div>
                        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                            <p class="text-sm font-medium text-gray-900">Sonic</p>
                            <p class="mt-2 flex items-center text-sm text-gray-500">
                            <!-- Heroicon name: solid/mail -->
                            <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                            </svg>
                            <span class="truncate">sonic@homesapp.app</span>
                            </p>
                        </div>
                        <div>
                            <div>
                            <p class="text-sm text-gray-900 hidden md:block">
                                Created on
                                <time datetime="2020-01-07">January 7, 2020</time>
                            </p>
                            <p class="mt-2 flex items-center text-sm text-gray-500">
                              <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-400">
                                Resolved
                              </span> 
                            </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div>
                        <!-- Heroicon name: solid/chevron-right -->
                        <i class="flex-2 fad fa-angle-right text-ha-blue-one text-xl"></i>
                    </div>
                    </div>
                </a>
              </li>

              <li>
                <a href="#" class="block hover:bg-gray-50">
                    <div class="flex items-center px-4 py-4 sm:px-6">
                    <div class="min-w-0 flex-1 flex items-center">
                        <div class="flex-shrink-0">
                        <img class="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixqx=qxgTj0gLwD&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">
                        </div>
                        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                            <p class="text-sm font-medium text-gray-900">Tails</p>
                            <p class="mt-2 flex items-center text-sm text-gray-500">
                            <!-- Heroicon name: solid/mail -->
                            <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                            </svg>
                            <span class="truncate">tails@homesap.app</span>
                            </p>
                        </div>
                        <div>
                            <div>
                            <p class="text-sm text-gray-900 hidden md:block">
                                Created on
                                <time datetime="2020-01-07">January 7, 2020</time>
                            </p>
                            <p class="mt-2 flex items-center text-sm text-gray-500">
                              <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-400">
                                In Progress
                              </span>   
                            </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div>
                        <!-- Heroicon name: solid/chevron-right -->
                        <i class="flex-2 fad fa-angle-right text-ha-blue-one hover:text-ha-blue-two text-xl"></i>
                    </div>
                    </div>
                </a>
              </li>

              <li>
                <a href="#" class="block hover:bg-gray-50">
                    <div class="flex items-center px-4 py-4 sm:px-6">
                    <div class="min-w-0 flex-1 flex items-center">
                        <div class="flex-shrink-0">
                        <img class="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixqx=qxgTj0gLwD&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">
                        </div>
                        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                            <p class="text-sm font-medium text-gray-900">Doctor Eggman</p>
                            <p class="mt-2 flex items-center text-sm text-gray-500">
                            <!-- Heroicon name: solid/mail -->
                            <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                            </svg>
                            <span class="truncate">doctorEggman@homesapp.app</span>
                            </p>
                        </div>
                        <div>
                            <div>
                            <p class="text-sm text-gray-900 hidden md:block">
                                Created on
                                <time datetime="2020-01-07">January 7, 2020</time>
                            </p>
                            <p class="mt-2 flex items-center text-sm text-gray-500"> 
                                <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-400">
                                New
                                </span>  
                            </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div>
                        <!-- Heroicon name: solid/chevron-right -->
                        <i class="flex-2 fad fa-angle-right text-ha-blue-one hover:text-ha-blue-two text-xl"></i>
                    </div>
                    </div>
                </a>
              </li>
          </ul>

          <div class="flex justify-center p-2">
              <a href="/maintenance" class="block text-ha-blue-one hover:text-ha-blue-two">
                  <div class="min-w-0 px-4  flex md:gap-4">
                      <p class="text-sm font-medium  truncate ">See more </p> 
                      <p><i class="fad fa-angle-right text-ha-blue-one hover:text-ha-blue-two text-xl"></i></p>
                  </div>
                    
              </a> 
          </div>

      </div>

    </div>
</div> 


</template>
 
<script lang="ts">
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import Chart from "chart.js";

export default defineComponent({
  name: "WidgetsMaintenanceTable",

  props: {
    charts: [],
  },

  setup() {
    const state = reactive({});

    const chartOptions = {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        xAxes: [
          {
            gridLines: false,
            scaleLabel: false,
            ticks: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            gridLines: false,
            scaleLabel: false,
            ticks: {
              display: false,
              suggestedMin: 0,
              suggestedMax: 10,
            },
          },
        ],
      },
    };

    onMounted(() => {
      var ctx = document.getElementById("chart_new");
      var chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: [1, 2, 1, 3, 5, 4, 7],
          datasets: [
            {
              backgroundColor: "rgba(254, 243, 199)",
              borderColor: "rgba(245, 158, 11)",
              borderWidth: 2,
              data: [1, 2, 1, 3, 5, 4, 7],
            },
          ],
        },
        options: chartOptions,
      });

      var ctx = document.getElementById("chart_inProgress");
      var chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: [2, 3, 2, 9, 7, 7, 4],
          datasets: [
            {
              backgroundColor: "rgba(209, 250, 229)",
              borderColor: "rgba(16, 185, 129)",
              borderWidth: 2,
              data: [2, 3, 2, 9, 7, 7, 4],
            },
          ],
        },
        options: chartOptions,
      });

      var ctx = document.getElementById("chart_resolved");
      var chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: [2, 3, 2, 9, 7, 7, 4],
          datasets: [
            {
              backgroundColor: "rgba(246, 109, 155, 0.1)",
              borderColor: "rgba(246, 109, 155, 0.8)",
              borderWidth: 2,
              data: [2, 3, 2, 9, 7, 7, 4],
            },
          ],
        },
        options: chartOptions,
      });
    });

    return {
      ...toRefs(state),
    };
  },
});
</script>