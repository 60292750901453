<template>
  <Header
    :header="header"
    :route="route"
    :btn_Title="btn_Title"
    :btnVisible="btnVisible"
  />

  <div class="flex justify-center p-6 lg:p-8">
    <form class="w-11/12" :v-on="handleFormSubmission">
      
      <div class="hidden"> 

        <h3 class="text-lg font-medium leading-6 text-gray-900">
          {{ title }}
        </h3>
        <!-- <p class="mt-1 text-sm leading-5 text-gray-500">[message]</p> --> 

        <!-- FORM FIELDS START -->
        <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-12">
          <div class="sm:col-span-3">
            <label
              for="lease"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Lease
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <select
                id="lease"
                disabled
                type="select"
                v-model="maintenance.lease"
                class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"
              >
                <option value="123 Broad st. Apt 3">123 Broad st. Apt 3</option>
                <option value="200 One dr. Suite 515">
                  200 One dr. Suite 515
                </option>
                <option value="290 Cranston st. Apt 1">
                  290 Cranston st. Apt 1
                </option>
              </select>
            </div>
          </div>

          <div class="sm:col-span-3">
            <label
              for="category"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Category
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <select
                id="category"
                disabled
                type="select"
                v-model="maintenance.category"
                class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"
              >
                <option value="Appliance">Appliance</option>
                <option value="Electrical">Electrical</option>
                <option value="House Exterior">House Exterior</option>
                <option value="HouseHold">HouseHold</option>
                <option value="Outdoors">Outdoors</option>
                <option value="Plumbing">Plumbing</option>
              </select>
            </div>
          </div>

          <div class="sm:col-span-3">
            <label
              for="subCategory"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Sub Category
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <select
                id="subCategory"
                disabled
                type="select"
                v-model="maintenance.subCategory"
                class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"
              >
                <option value="Dishwasher">Dishwasher</option>
                <option value="he">Heating & Cooling</option>
                <option value="Laundry / Vendor">Laundry / Vendor</option>
                <option value="Oven / Stove">Oven / Stove</option>
                <option value="Refrigerator">Refrigerator</option>
                <option value="Water Heater">Water Heater</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>

          <div class="sm:col-span-3">
            <label
              for="tenantId"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Tenant
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <input
                id="tenantId"
                type="text"
                disabled
                v-model="maintenance.tenant"
                class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"
              />
            </div>
          </div>

          <div class="sm:col-span-3">

            <label
              for="email"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Email
              <i class="fas fa-asterisk required" aria-required="true"></i>
            </label>  
            <div class="mt-1 relative rounded-md shadow-sm">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"> 
                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
              </div>
              <input 
                id="email"
                type="text"
                autoComplete="email"
                disabled 
                v-model="maintenance.email"
                  class="block w-full pl-10  border-gray-300 rounded-md shadow-sm 
                focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm" placeholder="you@example.com">
            </div>

          </div>

          <div class="sm:col-span-3">
            <label
              for="phoneNumber"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Tel. Number
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <input
                id="phoneNumber"
                type="text"
                disabled
                v-model="maintenance.phoneNumber"
                class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"
              />
            </div>
          </div> 

          <div class="sm:col-span-6">
            <label
              for="avaible"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Available Date & Time
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <input
                id="avaible"
                type="text"
                disabled
                v-model="maintenance.avaible"
                class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"
              />
            </div>
          </div>

          <div class="sm:col-span-12">
            <label
              for="reasons"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Describe the issue
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <textarea
                id="reasons"
                v-model="maintenance.reasons"
                disabled
                maxlength="1000"
                placeholder="Enter some appropriate issue details."
                class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm h-40"
              />
            </div>
          </div>
        </div>

        <div class="pt-5 mt-8 border-t border-gray-200 ">
          <div class="sm:col-span-2 mt-2">
            <label for="absence" class="flex items-center text-sm font-medium">
              <input
                type="checkbox"
                id="absence"
                disabled
                v-model="maintenance.absence"
                class="form-checkbox text-ha-green-one hover:bg-ha-green-two"
              />
              <span class="ml-2"
                >Authorisation to enter in tenant's absense</span
              >
            </label>
          </div>

          <div class="sm:col-span-2 mt-2">
            <label
              for="maintenancesConcernedLandload"
              class="flex items-center text-sm font-medium"
            >
              <input
                type="checkbox"
                disabled
                v-model="maintenance.maintenancesConcerned"
                class="form-checkbox text-ha-green-one hover:bg-ha-green-two"
              />
              <span class="ml-2"
                >Is this Maintenance concerned with the landloard?</span
              >
            </label>
          </div>

          <div class="sm:col-span-2 mt-2">
            <label
              for="hasRegistered"
              class="flex items-center text-sm font-medium"
            >
              <input
                type="checkbox"
                id="hasRegistered"
                v-model="maintenance.hasRegistered"
                disabled
                class="form-checkbox text-ha-green-one hover:bg-ha-green-two"
              />
              <span class="ml-2"
                >How you ever registered this Maintenance with the
                landloard?</span
              >
            </label>
          </div>

          <div class="sm:col-span-2 mt-2">
            <label
              for="anyImprovementAftherContacting"
              class="flex items-center text-sm font-medium"
            >
              <input
                type="checkbox"
                class="form-checkbox text-ha-green-one hover:bg-ha-green-two"
                disabled
                v-model="maintenance.anyImprovementAftherContacting"
              />
              <span class="ml-2"
                >Did any improvement happen after contacting the
                landloard?</span
              >
            </label>
          </div>

          <div class="sm:col-span-2 mt-2">
            <label
              for="leaseId"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Status
              <i class="fas fa-asterisk required" aria-required="true"></i>
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <select
                id="leaseId"
                required
                type="select"
                v-model="maintenance.leaseId"
                class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"
              >
                <option value="I">In Progress</option>
                <option value="C">Resolved</option>
              </select>
            </div>
          </div>

          <div class="sm:col-span-3 mt-2">
            <label
              for="note"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Note
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <textarea
                id="note"
                type="text"
                v-model="maintenance.note"
                maxlength="300"
                placeholder="Enter some appropriate note."
                class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm h-40"
              />
            </div>
          </div>
        </div>

        <!-- FORM FIELDS END -->
      </div>

      <div class="bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            {{ title }}
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Maintenance details and request.
          </p>
        </div>
        <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl class="grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-3">
            <div class="sm:col-span-1 col-span-2">
              <dt class="text-sm font-medium text-gray-500">Lease</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ maintenance.lease }}
              </dd>
            </div>
            <div class="sm:col-span-1 col-span-2">
              <dt class="text-sm font-medium text-gray-500">Status</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ maintenance.status }}
              </dd>
            </div>
            <div class="sm:col-span-1 col-span-2">
              <dt class="text-sm font-medium text-gray-500">Category</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ maintenance.category }}
              </dd>
            </div>
            <div class="sm:col-span-1 col-span-2">
              <dt class="text-sm font-medium text-gray-500">Sub Category</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ maintenance.subCategory }}
              </dd>
            </div>
            <div class="sm:col-span-1 col-span-2">
              <dt class="text-sm font-medium text-gray-500">Tenant</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ maintenance.tenant }}
              </dd>
            </div>
            <div class="sm:col-span-1 col-span-2">
              <dt class="text-sm font-medium text-gray-500">Email</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ maintenance.email }}
              </dd>
            </div>
            <div class="sm:col-span-1 col-span-2">
              <dt class="text-sm font-medium text-gray-500">Phone Number</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ maintenance.phoneNumber }}
              </dd>
            </div>
            <div class="sm:col-span-1 col-span-2">
              <dt class="text-sm font-medium text-gray-500">
                Available Date & Time
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ maintenance.avaible }}
              </dd>
            </div>
            <div class="sm:col-span-1 col-span-2">
              <dt class="text-sm font-medium text-gray-500">
                Authorisation to enter in tenant's absense
              </dt>
              <dd
                v-if="maintenance.absense === true"
                class="mt-1 text-sm text-gray-900"
              >
                Yes
              </dd>
              <dd v-else class="mt-1 text-sm text-gray-900">No</dd>
            </div>
            <div class="sm:col-span-1 col-span-2">
              <dt class="text-sm font-medium text-gray-500">
                Is this Maintenance concerned with the landloard?
              </dt>
              <dd
                v-if="maintenance.concerned === true"
                class="mt-1 text-sm text-gray-900"
              >
                Yes
              </dd>
              <dd v-else class="mt-1 text-sm text-gray-900">No</dd>
            </div>
            <div class="sm:col-span-1 col-span-2">
              <dt class="text-sm font-medium text-gray-500">
                How you ever registered this Maintenance with the landloard?
              </dt>
              <dd
                v-if="maintenance.hasRegistered === true"
                class="mt-1 text-sm text-gray-900"
              >
                Yes
              </dd>
              <dd v-else class="mt-1 text-sm text-gray-900">No</dd>
            </div>
            <div class="sm:col-span-1 col-span-2">
              <dt class="text-sm font-medium text-gray-500">
                Did any improvement happen after contacting the landloard?
              </dt>
              <dd
                v-if="maintenance.anyImprovementAftherContacting === true"
                class="mt-1 text-sm text-gray-900"
              >
                Yes
              </dd>
              <dd v-else class="mt-1 text-sm text-gray-900">No</dd>
            </div>
            <div class="sm:col-span-3 col-span-2">
              <dt class="text-sm font-medium text-gray-500">Issue Detail</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ maintenance.issue }}
              </dd>
            </div>
            <div class="sm:col-span-3 col-span-2">
              <dt class="text-sm font-medium text-gray-500">Note</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ maintenance.note }}
              </dd>
            </div>
            <div class="sm:col-span-3 col-span-2">
              <dt class="text-sm font-medium text-gray-500">Attachments</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <ul
                  class="border border-gray-200 rounded-md divide-y divide-gray-200"
                >
                  <li
                    class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                  >
                    <div class="w-0 flex-1 flex items-center">
                      <!-- Heroicon name: solid/paper-clip -->
                      <svg
                        class="flex-shrink-0 h-5 w-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <span class="ml-2 flex-1 w-0 truncate">
                        resume_back_end_developer.pdf
                      </span>
                    </div>
                    <div class="ml-4 flex-shrink-0">
                      <a
                        href="#"
                        class="font-medium text-ha-blue-one hover:text-ha-blue-two"
                      >
                        Download
                      </a>
                    </div>
                  </li>
                  <li
                    class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                  >
                    <div class="w-0 flex-1 flex items-center">
                      <!-- Heroicon name: solid/paper-clip -->
                      <svg
                        class="flex-shrink-0 h-5 w-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <span class="ml-2 flex-1 w-0 truncate">
                        coverletter_back_end_developer.pdf
                      </span>
                    </div>
                    <div class="ml-4 flex-shrink-0">
                      <a
                        href="#"
                        class="font-medium text-ha-blue-one hover:text-ha-blue-two"
                      >
                        Download
                      </a>
                    </div>
                  </li>
                </ul>
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <div class="pt-5 mt-8 border-t border-gray-200">
        <div class="flex justify-end">
          <span class="inline-flex rounded-md shadow-sm">
            <a
              class="px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
              href="/maintenance"
            >
              Cancel
            </a>
          </span>
          <span class="inline-flex ml-3 rounded-md shadow-sm">
            <button
              type="submit"
              class="inline-flex justify-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-ha-rose-zero border border-transparent rounded-md hover:bg-ha-rose-two focus:outline-none focus:border-ha-rose-zero focus:shadow-outline-ha-rose-one active:bg-ha-rose-zero"
            >
              Update
            </button>
          </span>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, toRefs, watch } from "vue";
import { useRoute } from "vue-router";
import { getTenantMaintenances } from "../utils/fakeApi";
import Axios from "../axios";
import Header from "../components/Header.vue";

export default defineComponent({
  name: "ContactDetail",

  components: {
    Header,
  },

  setup() {
    const route = useRoute();

    const state = reactive({
      header: "Maintenance",
      route: "maintenance-create",
      btn_Title: "Create Maintenance",
      btnVisible: true,
      title: "Maintenance Detail",
      response: null,
      maintenanceId: null,
      maintenance: {
        lease: "",
        createDate: "",
        status: "",
        avaible: "",
        issue: "",
        tenant: "",
        email: "",
        phoneNumber: "",
        category: "",
        subCategory: "",
        absense: "",
        concerned: "",
        hasRegistered: "",
        anyImprovementAftherContacting: "",
        note: "",
      },
    });

    onMounted(() => {
      const maintenances = getTenantMaintenances();
      state.maintenanceId = route.params.id;

      if (
        !Number.isInteger(state.maintenanceId) &&
        state.maintenanceId < maintenances.length
      ) {
        state.maintenance = maintenances[state.maintenanceId];
      }
    });

    watch(
      () => route.name,
      () => {
        for (let key in state.maintenance) {
          state.maintenance[key] = "";
        }
        state.maintenanceId = null;
      }
    );

    const handleFormSubmission = async () => {
      state.response = await Axios({
        method: "post",
        url: `/maintenance/create?maintenance${state}`,
      });
    };

    return {
      ...toRefs(state),
      handleFormSubmission,
    };
  },
});
</script>