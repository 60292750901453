<template>
  <div class="mt-8">
    <h3
      class="px-3 text-xs font-semibold leading-4 tracking-wider text-gray-500 uppercase"
      id="teams-headline"
    >
      {{ headerTitle }}
    </h3>

    <div
      v-for="(items, index) in labels"
      class="mt-1 space-y-1"
      role="group"
      aria-labelledby="teams-headline"
      :key="index"
    >
      <router-link
        v-for="(item, ikey) in items"
        :key="ikey"
        :to="item.link"
        class="flex items-center px-3 py-2 text-sm font-medium leading-5 text-gray-600 transition 
        duration-150 ease-in-out rounded-md group hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-50"
      >
        <span class="w-2.5 h-2.5 mr-4 rounded-full" :style="{'background-color': item.color}"></span>
        <span class="truncate">{{ item.title }}</span>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "Categories",

  props: {
    labels: {
      type: Array,
    },

    headerTitle: {
      type: String,
    },
  },
});
</script>