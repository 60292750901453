<template>
  <table class="min-w-full">
    <thead>
      <tr>
        <th
          class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
        >
          Name
        </th>
        <th
          class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
        >
          Title
        </th>
        <th
          class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
        >
          Phone Number
        </th> 
        <th class="px-6 py-3 bg-gray-50 text-right">
                  <div>  
          <button type="button" @click="toggleVisible()" class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white
            bg-ha-rose-zero hover:bg-ha-rose-one focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ha-rose-zero" title="add new payment">
          <!-- Heroicon name: solid/plus -->
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
          </svg>
          </button> 
        </div>
        </th>
      </tr>
    </thead>
    <tbody class="bg-white divide-y">
      <ContactTableItem
        v-for="(contact, index) in contacts"
        :key="index"
        :contact="contact"
      />
    </tbody>
  </table>

  <ContactForm :showModal="showModal" @close="showModal = false" />
  
  <Pagination/>

</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import ContactTableItem from "./ContactTableItem.vue";
import ContactForm from "./ContactForm.vue";
import Pagination from "./Pagination.vue";

export default defineComponent({
  name: "ContactTable",
  components: { ContactTableItem, Pagination, ContactForm },
  props: {
    contacts: Array,
  },

  setup() {  

    const state = reactive({ 
        showModal: false

    });

    onMounted(() => {
                 
    });

    function toggleVisible() {
        state.showModal = !state.showModal;
    }

    return {
      ...toRefs(state),  
      toggleVisible,
    };
            
    },




});
</script>