<template>
  <div class="space-y-1">
    <div v-for="(items, key) in menuItems" :key="key">
      <template v-for="(item, index) in items">
        <template v-if="index > 0">
          <router-link
            :to="item.link"
            :key="index"
            class="flex items-center px-2 py-2 text-sm font-medium leading-5  transition duration-150 ease-in-out rounded-md group hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-50"
          >
            <span
              class="w-6 mr-3 text-gray-500 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-600" 
            >
              <i class="text-lg fad" :class="'fa-' + item.icon"></i>
            </span>
            <span class="truncate">{{ item.title }}</span>
          </router-link>
        </template>
        <template v-else>
          <router-link
            :to="item.link"
            :key="index"
            class="flex items-center px-2 py-2 text-sm font-medium leading-5 transition duration-150 ease-in-out bg-gray-200 rounded-md group focus:outline-none focus:bg-gray-50"
          >
            <span
              class="w-6 mr-3 text-gray-500 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-600"
               
            >
              <i class="text-lg fad" :class="'fa-' + item.icon"></i>
            </span>
            <span class="truncate">{{ item.title }}</span>
          </router-link>
        </template>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
export default defineComponent({
  name: "SidebarMenu",

  props: {
    menuItems: {
      type: Array,
    },

    hideCallBack: {
      type: Function,
      required: false,
    },
  },

  setup() {
    const state = reactive({});

    return { ...toRefs(state) };
  },
});
</script>