<template>
  <Header
    :header="header"
    :route="route"
    :btn_Title="btn_Title"
    :btnVisible="btnVisible"
  />

  <div class="flex justify-center p-6 lg:p-8">
    <form class="w-11/12" :v-on="handleFormSubmission"> 
      <div class="flex flex-col w-full">
        <div>
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            {{
              propertyId
                ? `${property.address} ${property.city}, ${property.state} ${property.zip}`
                : "Create Property"
            }}
          </h3>
          <!-- <p class="mt-1 text-sm leading-5 text-gray-500">[message]</p> -->
        </div>

        <!-- FORM FIELDS START -->
        <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
          
          <div class="sm:col-span-2">
            <label
              for="lease"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Lease
              <i class="fas fa-asterisk required" aria-required="true"></i>
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <select
                id="lease"
                required
                type="select"
                v-model="maintenance.lease"
                class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"
              >
                <option value="123 Broad st. Apt 3">
                  123 Broad st. Apt 3
                </option>
                <option value="200 One dr. Suite 515">
                  200 One dr. Suite 515
                </option>
                <option value="290 Cranston st. Apt 1">
                  290 Cranston st. Apt 1
                </option>
              </select>
            </div>
          </div>

          <div class="sm:col-span-2">
            <label
              for="category"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Category
              <i class="fas fa-asterisk required" aria-required="true"></i>
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <select
                id="category"
                required
                type="select"
                v-model="maintenance.category"
                class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"
              >
                <option value="Appliance">Appliance</option>
                <option value="Electrical">Electrical</option>
                <option value="House Exterior">House Exterior</option>
                <option value="HouseHold">HouseHold</option>
                <option value="Outdoors">Outdoors</option>
                <option value="Plumbing">Plumbing</option>
              </select>
            </div>
          </div>

          <div class="sm:col-span-1">
            <label
              for="subCategory"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Sub Category
              <i class="fas fa-asterisk required" aria-required="true"></i>
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <select
                id="subCategory"
                required
                type="select"
                v-model="maintenance.subCategory"
                class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"
              >
                <option value="Dishwasher">Dishwasher</option>
                <option value="he">Heating & Cooling</option>
                <option value="Laundry / Vendor">Laundry / Vendor</option>
                <option value="Oven / Stove">Oven / Stove</option>
                <option value="Refrigerator">Refrigerator</option>
                <option value="Water Heater">Water Heater</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>

          <div class="sm:col-span-1">
            <label
              for="reasons"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Available Date & Time
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <input
                id="reasons"
                type="text"
                v-model="maintenance.avaible"
                class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"
              />
            </div>
          </div>
        </div>

        <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-3">
          <div class="sm:col-span-2">
            <label
              for="maintenancesConcernedLandload"
              class="flex items-center text-sm font-medium"
            >
              <input
                type="checkbox"
                id="absence"
                v-model="maintenance.absence"
                class="focus:ring-ha-rose-zero h-4 w-4 text-ha-rose-one border-ha-rose-zero rounded"
              /> 

              <span class="ml-2"
                >Authorisation to enter in tenant's absense</span
              >
            </label>
          </div>

          <div class="sm:col-span-2">
            <label
              for="concerned"
              class="flex items-center text-sm font-medium"
            >
              <input
              id="concerned"
              type="checkbox"
              v-model="maintenance.concerned"
              class="focus:ring-ha-rose-zero h-4 w-4 text-ha-rose-one border-ha-rose-zero rounded"
              />
              <span class="ml-2"
                >Is this Maintenance concerned with the landloard?</span
              >
            </label>
          </div>

          <div class="sm:col-span-2">
            <label
              for="registeredMaintenances"
              class="flex items-center text-sm font-medium"
            >
              <input
                type="checkbox"
                v-model="maintenance.registeredMaintenances"
                class="focus:ring-ha-rose-zero h-4 w-4 text-ha-rose-one border-ha-rose-zero rounded"
              />
              <span class="ml-2"
                >How you ever registered this Maintenance with the
                landloard?</span
              >
            </label>
          </div>

          <div class="sm:col-span-2">
            <label
              for="anyImprovementAftherContacting"
              class="flex items-center text-sm font-medium"
            >
              <input
                type="checkbox"
                class="focus:ring-ha-rose-zero h-4 w-4 text-ha-rose-one border-ha-rose-zero rounded"
                v-model="maintenance.anyImprovementAftherContacting"
              />
              <span class="ml-2"
                >Did any improvement happen after contacting the
                landloard?</span
              >
            </label>
          </div>
        </div>

        <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-3 border-t border-gray-200">
          <div class="mt-6 mb-6">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Issue Details
            </h3>
            <!-- <p class="mt-1 text-sm leading-5 text-gray-500">[message]</p> -->
          </div>

          <div class="sm:col-span-6">
            <label
              for="issue"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Describe the issue
              <i class="fas fa-asterisk required" aria-required="true"></i>
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <textarea
                id="issue"
                v-model="maintenance.issue"
                required
                maxlength="1000"
                placeholder="Enter some appropriate issue details."
                class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm h-40"
              />
            </div>
          </div>

          <div class="sm:col-span-6">
            <FileUpload />
          </div>
        </div>

        <!-- FORM FIELDS END -->
      </div> 

      <div class="pt-5 mt-8 border-t border-gray-200 ">
        <div class="flex justify-end">
          <span class="inline-flex rounded-md shadow-sm">
            <a
              class="px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
              href="/maintenance"
            >
              Cancel
            </a>
          </span>
          <span class="inline-flex ml-3 rounded-md shadow-sm">
            <button
              type="submit"
              v-if="maintenanceId"
              class="inline-flex justify-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-ha-rose-zero border border-transparent rounded-md hover:bg-ha-rose-two focus:outline-none focus:border-ha-rose-zero focus:shadow-outline-ha-rose-one active:bg-ha-rose-zero"
            >
              Save
            </button>
            <button
              v-else
              type="submit"
              class="inline-flex justify-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-ha-rose-zero border border-transparent rounded-md hover:bg-ha-rose-two focus:outline-none focus:border-ha-rose-zero focus:shadow-outline-ha-rose-one active:bg-ha-rose-zero"
            >
              Create
            </button>
          </span>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, toRefs, watch } from "vue";
import { useRoute } from "vue-router";
import { getMyMaintenances } from "../utils/fakeApi";
import Axios from "../axios";
import Header from "../components/Header.vue";
import FileUpload from "../components/FileUpload.vue";

export default defineComponent({
  name: "MaintenanceForm",

  components: {
    Header,
    FileUpload,
  },

  setup() {
    const route = useRoute();

    const state = reactive({
      header: "Maintenance",
      route: "maintenance-create",
      btn_Title: "Maintenance",
      btnVisible: false,
      title: "",
      response: null,
      maintenanceId: null,
      maintenance: {
        lease: "",
        createDate: "",
        status: "",
        avaible: "",
        issue: "",
        tenant: "",
        email: "",
        phoneNumber: "",
        category: "",
        subCategory: "",
        absense: "",
        concerned: "",
        hasRegistered: "",
        anyImprovementAftherContacting: "",
        note: "",
        avatar: "",
      },
    });

    onMounted(() => {
      const maintenances = getMyMaintenances();
      state.maintenanceId = route.params.id;

      if (
        !Number.isInteger(state.maintenanceId) &&
        state.maintenanceId < maintenances.length
      ) {
        state.title = "Edit Maintenance";
        state.maintenance = maintenances[state.maintenanceId];
      } else {
        state.title = "Create Maintenance";
      }
    });

    watch(
      () => route.name,
      () => {
        for (let key in state.maintenance) {
          state.maintenance[key] = "";
        }
        state.maintenanceId = null;
      }
    );

    const handleFormSubmission = async () => {
      state.response = await Axios({
        method: "post",
        url: `/maintenance/create?maintenance${state}`,
      });
    };

    return {
      ...toRefs(state),
      handleFormSubmission,
    };
  },
});
</script>