<template>
  <div :class="{ flex: isVisible, hidden: !isVisible }">
    <div class="fixed inset-0 z-40 flex">
      <div class="fixed inset-0">
        <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
      </div>
      <div
        class="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-white"
      >
        <div class="absolute top-0 right-0 p-1 -mr-14">
          <button
            @click="hideCallBack"
            class="flex items-center justify-center w-12 h-12 rounded-full focus:outline-none focus:bg-gray-600"
            aria-label="Close sidebar"
          >
            <svg
              class="w-6 h-6 text-white"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div class="flex items-center flex-shrink-0 px-4">
          <img
            class="w-auto h-8"
            src="../../public/img/logo.png"
            alt="Homes-app"
          />
        </div>
        <div class="flex-1 h-0 mt-5 overflow-y-auto">
          <nav class="px-2">
            <SidebarMenu
              :menuItems="sideBarMenuItems"
              :hideCallBack="hideCallBack"
            />
            <Categories :labels="labels" :headerTitle="labelHeader" />
          </nav>
        </div>
      </div>
      <div class="flex-shrink-0 w-14"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";

import SidebarMenu from "./SidebarMenu.vue";
import Categories from "./Categories.vue";

export default defineComponent({
  name: "SidebarApp",
  components: {
    SidebarMenu,
    Categories,
  },

  props: {
    isVisible: {
      type: Boolean,
    },
    hideCallBack: {
      type: Function,
    },
  },

  setup() {
    const state = reactive({
      Visible: true,
      labelHeader: "Property Types",
      labels: [
        [
          { title: "Residential", link: "", color: "#FA376C" },
          { title: "Comercial", link: "", color: "#FFA780" },
          { title: "Student Housing", link: "", color: "#49C0DC" },
          { title: "Community Associations", link: "", color: "#9BEEC1" },
        ],
      ],

      sideBarMenuItems: [
        [
          { title: "Dashboard", link: "/", icon: "tachometer-alt-average", color: "blue-500" },
          { title: "Lease", link: "/leases", icon: "file-contract", color: "green-500" },
          { title: "Properties", link: "/properties", icon: "city", color: "ha-yellow-one" },
          { title: "Maintenance", link: "/maintenance", icon: "toolbox", color: "red-500" },
          { title: "Fanancial", link: "/financial", icon: "wallet", color: "indigo-500" },
          { title: "Contacts", link: "contacts", icon: "address-card", color: "ha-rose-one" },
        ],
      ],
    });

    function toggleVisible() {
      state.Visible = !state.Visible;
    }

    return { ...toRefs(state), toggleVisible };
  },
});
</script>