<template>
  <div class="hidden lg:flex lg:flex-shrink-0">
    <div
      class="flex flex-col w-64 pt-5 pb-4 bg-gray-100 border-r border-gray-200"
    >
      <div class="flex items-center flex-shrink-0 px-6">
        <img
          class="w-auto h-8"
          src="../../public/img/logo.png"
          alt="Homes-app"
        />
      </div>
      <div class="flex flex-col flex-1 h-0 overflow-y-auto">
        <div class="relative inline-block px-3 mt-6 text-left">
          <div>
            <button
              @click="toggleVisible()"
              @blur="visible = false"
              type="button"
              class="group w-full rounded-md px-3.5 py-2 text-sm leading-5 font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-500 focus:outline-none focus:bg-gray-200 focus:border-blue-300 active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
              id="options-menu"
              aria-haspopup="true"
              aria-expanded="true"
            >
              <div class="flex items-center justify-between w-full">
                <div class="flex items-center justify-between space-x-3">
                  <img
                    class="flex-shrink-0 w-10 h-10 bg-gray-300 rounded-full"
                    :src="user.avatar_url"
                    alt=""
                  />
                  <div class="flex-1">
                    <h2 class="text-sm font-medium leading-5 text-gray-900">
                      {{ user.name }}
                    </h2>
                    <p class="text-sm leading-5 text-gray-500 truncate">
                      {{ user.email }}
                    </p>
                  </div>
                </div>
                <svg
                  class="w-5 h-5 text-gray-400 group-hover:text-gray-500 -mt-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </button>
          </div>
          <ContextMenu :lists="contextMenuItems" :isVisible="visible"/>
        </div>
        <div class="hidden px-3 mt-5">
          <label htmlFor="search" class="sr-only"> Search </label>
          <div class="relative mt-1 rounded-md shadow-sm">
            <div
              class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
            >
              <svg
                class="w-4 h-4 mr-3 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <input
              id="search"
              class="block w-full form-input pl-9 sm:text-sm sm:leading-5"
              placeholder="Search"
            />
          </div>
        </div>
        <nav class="px-3 mt-6">
          <SidebarMenu :menuItems="sideBarMenuItems" />
          <Categories :labels="labels" :headerTitle="labelHeader"/>
        </nav>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, reactive, toRefs } from "vue";

import SidebarMenu from "./SidebarMenu.vue";
import Categories from "./Categories.vue";
import ContextMenu from "./ContextMenu.vue";

export default defineComponent({
  name: "Sidebar",
  components: {
    SidebarMenu,
    Categories,
    ContextMenu,
  },

  setup() {
    const user = inject("user");

    const state = reactive({
      visible: false,
      labelHeader: "Property Types",
      contextMenuItems: [
        [
          { title: "My Account", link: "", subItems: [] },
          { title: "Settings", link: "", subItems: [] },
          { title: "Refer a friend", link: "", subItems: [] },
        ],
      ],

      sideBarMenuItems: [
        [
          {
            title: "Dashboard",
            link: "/",
            icon: "tachometer-alt-average",
            color: "blue-500",
          },
          {
            title: "Lease",
            link: "/leases",
            icon: "file-contract",
            color: "green-500",
          },
          {
            title: "Properties",
            link: "/properties",
            icon: "city",
            color: "ha-yellow-one",
          },
          {
            title: "Maintenance",
            link: "/maintenance",
            icon: "toolbox",
            color: "red-500",
          },
          {
            title: "Financial",
            link: "/financial",
            icon: "wallet",
            color: "indigo-500",
          },
          {
            title: "Contacts",
            link: "/contacts",
            icon: "address-card",
            color: "ha-rose-one",
          },
        ],
      ],

      labels: [
        [
          { title: "Residential", link: "", color: "#FA376C" },
          { title: "Comercial", link: "", color: "#FFA780" },
          { title: "Student Housing", link: "", color: "#49C0DC" },
          { title: "Community Associations", link: "", color: "#9BEEC1" },
        ],
      ],
    });

    function toggleVisible() {
      state.visible = !state.visible;
    }

    return {
      ...toRefs(state),
      user,
      toggleVisible,
    };
  },
});
</script>