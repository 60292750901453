<template>
  <div 
    :class="[
      showModal ? 'lg:flex' : 'lg:hidden',
      'z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg hidden ',
    ]" @click.self="close"
  >
    <div class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" 
    role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
      <div class="py-1" role="none" v-for="(list, index) in lists" :key="index">
        <template v-for="(item, index) in list">
          <template v-if="item.subItems.length > 0"></template>
          <template v-else>
            <template v-if="index === list.length - 1">
              <WidgetsContextMenuItem
                :key="index"
                :title="item.title"
                link="item.link"
              />
              <div
                :key="'border-key-' + index"
                class="border-t border-gray-100"
              ></div>
            </template>
            <WidgetsContextMenuItem
              v-else
              :key="index"
              :title="item.title"
              :link="item.link"
            />
          </template>
        </template>
      </div> 
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import WidgetsContextMenuItem from "./WidgetsContextMenuItem.vue";

export default defineComponent({
    name: "WidgetsContextMenu",
  components: { WidgetsContextMenuItem },
  props: {
    lists: Array,
    showModal: Boolean, 
  },
  
  setup() {  
    const state = reactive({
      header: "Lease", 

    });
  
    function close() {
      this.$emit('close');  
      this.showModal = !this.showModal;
    }

    return {
      ...toRefs(state), 
      close,
    };
  },

}); 

</script>