<template>
  <table class="min-w-full divide-y divide-gray-200">
    <thead>
      <tr>
        <th
          class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
        >
          Lease
        </th>
        <th
          class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
        >
          Open on
        </th>
        <th
          class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
        >
          Status
        </th> 
        <th class="px-6 py-3 bg-gray-50"></th>
      </tr>
    </thead>
    <tbody class="bg-white divide-y divide-gray-200">
      <TenantMaintenanceTableItem
        v-for="(maintenance, index) in maintenances"
        :key="index"
        :maintenance="maintenance"
      />
    </tbody>
  </table>

  <Pagination/>

</template> 
 
<script lang="ts">
import { defineComponent } from "vue";
import TenantMaintenanceTableItem from "./TenantMaintenanceTableItem.vue";
import Pagination from "./Pagination.vue";

export default defineComponent({
  name: "TenantMaintenanceTable",
  components: { TenantMaintenanceTableItem, Pagination },
  props: {
    maintenances: Array,
  },
});

</script>