<template>
<Header :header="header" :route="route" :btn_Title="btn_Title" :btnVisible="btnVisible" />
  
<div class="flex flex-col items-center p-6 -mb-6  lg:pl-16">
  <div class="w-full max-w-screen-xl overflow-x-auto sm:-mx-6 lg:-mx-10" >
    <div class="">
      <div @click="toggleActive = !toggleActive" class="flex items-center">
        <Switch :toggleActive="toggleActive" />
      </div>
    </div> 
  </div> 
</div>

  <div class="flex flex-col items-center p-6 lg:p-8">
    <div class="w-full max-w-screen-xl -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div
          class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg"
        >
          <LeaseTable :leases="leases" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent, onMounted, reactive, toRefs } from "vue"; 
import LeaseTable from "../components/LeaseTable.vue"; 
import { getLeases } from "../utils/fakeApi";
import Header from "../components/Header.vue";
import Switch from "../components/Switch.vue";

export default defineComponent({ 
  name: "LeaseIndex",

  components: {
    LeaseTable,
    Header,
    Switch
  },

  setup() {
    const state = reactive({
      leases: [],
      header: 'Leases',
      route: 'lease-create',
      btn_Title: 'Create Lease',
      btnVisible: false,
      toggleActive: false

    });

    onMounted(() => {
      state.leases = getLeases().map((lease, index) => {
        return Object.assign(lease, { id: index });
      });
    });

    return {
      ...toRefs(state),
    };
  },
});
</script>