<template> 
 
<div v-if="showModal"  @click.self="close" class="fixed z-10 inset-0 " aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div @click.prevent="close" class="fixed inset-0 bg-gray-500  bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

    <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-100 translate-y-0 sm:scale-100"
    -->
    <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left 
      overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
      <div>
        <div class="flex justify-center ">
          <form class="w-full" :v-on="handleFormSubmission"> 
            <div class="flex flex-col w-full">
              <div>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  {{
                    propertyId
                      ? "Edit Contact"
                      : "Add Contact"
                  }}
                </h3>
                <!-- <p class="mt-1 text-sm leading-5 text-gray-500">[message]</p> -->
              </div>

              <!-- FORM FIELDS START -->
                <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                  
                  <div class="sm:col-span-6">
                    <label
                      for="avatar"
                      class="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Profile Picture 
                    </label>
                    <div class="mt-1 rounded-md shadow-sm">
                      <input
                        id="avatar" 
                        type="file"    
                        class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm 
                        focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"
                      />
                    </div>
                  </div>
                  
                  <div class="sm:col-span-3">
                    <label
                      for="name"
                      class="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Name
                      <i class="fas fa-asterisk required" aria-required="true"></i>
                    </label>
                    <div class="mt-1 rounded-md shadow-sm">
                      <input
                        id="name"
                        type="text"
                        required  
                        v-model="contact.name" 
                        class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm 
                        focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"

                      />
                    </div>
                  </div>
                          
                  <div class="sm:col-span-3">

                    <label
                      for="email"
                      class="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Email
                      <i class="fas fa-asterisk required" aria-required="true"></i>
                    </label>  
                    <div class="mt-1 relative rounded-md shadow-sm">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <!-- Heroicon name: solid/mail -->
                        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                          <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                        </svg>
                      </div>
                      <input 
                        id="email"
                        type="text"
                        autoComplete="email" 
                        v-model="contact.email" 
                        required   
                          class="block w-full pl-10  border-gray-300 rounded-md shadow-sm 
                        focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm" placeholder="you@example.com">
                    </div>

                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="phoneNumber"
                      class="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Phone
                      <i class="fas fa-asterisk required" aria-required="true"></i>
                    </label>
                    <div class="mt-1 rounded-md shadow-sm">
                      <input
                        id="phoneNumber"
                        type="text"
                        autoComplete="tel"   
                        v-model="contact.phone"  
                        required
                        class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm 
                        focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"
                      />
                    </div>
                  </div> 

                  <div class="sm:col-span-3">

                    <label
                      for="role"
                      class="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Role
                      <i class="fas fa-asterisk required" aria-required="true"></i>
                    </label>
                    <div class="mt-1 rounded-md shadow-sm">
                      <input
                        id="role"
                        type="text"  
                        v-model="contact.role" 
                        required   
                        class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm 
                        focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"
                      />
                    </div>
                  </div>
                  
                </div> 
              <!-- FORM FIELDS END -->
            </div> 
          </form>
        </div> 
      </div>
      <div class="pt-5 mt-8 border-t border-gray-200 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <button type="button" class="w-full inline-flex  justify-center rounded-md border border-transparent shadow-sm px-4 py-2
          text-white transition duration-150 ease-in-out bg-ha-rose-zero  hover:bg-ha-rose-two focus:outline-none focus:border-ha-rose-zero 
          focus:shadow-outline-ha-rose-one active:bg-ha-rose-zero sm:col-start-2 sm:text-sm">
          Update
        </button>
        <button type="button" @click.prevent="close" class="mt-3 w-full inline-flex justify-center shadow-sm px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out border
        border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 sm:mt-0 sm:col-start-1 sm:text-sm">
          Cancel
        </button>
      </div>
    </div>

  </div>
</div> 

</template>
 

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, toRefs, watch } from "vue";
import { useRoute } from "vue-router";
import { getContacts } from "../utils/fakeApi";
import Axios from "../axios";
import Header from "./Header.vue";

export default defineComponent({
  name: "ContactForm",

  components: {
    Header,
  },
  props: {
    showModal: Boolean, 
    id: Number
  },

  setup(props) {
    const route = useRoute();

    const state = reactive({
      header: "Lease",
      route: "",
      btn_Title: "",
      btnVisible: false,
      response: null, 
      contactId: null,  
      contact: {
        name: "",
        title: "",
        phone: "",
        email: "",
        avatar: "",  
      }

    }); 
   
    onMounted(() => {
      
      const contacts = getContacts();      
      state.contactId = Number(route.params.id) > 0 ? route.params.id : props.id; 
     
      if (
        !Number.isInteger(state.contactId) &&
        state.contactId < contacts.length
      ) {
        state.contact = contacts[state.contactId];   
      }

    });

    watch(
      () => route.name,
      () => {
        for (let key in state.contact) {
          state.contact[key] = "";
        }
        state.contactId = null;
      } 
    );

    
    const handleFormSubmission = async () => {
      state.response = await Axios({
        method: "post",
        url: `/contact/create?contact${state}`,
      });
    };
  
    function close() {
      this.$emit('close');  
    }
    

    return {
      ...toRefs(state),
      handleFormSubmission,
      close,
    };
  },
  
});
</script>