<template>
  <div class="px-4 py-4 border-b border-gray-200 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
    <div class="flex-1 min-w-0">
      <h1 class="text-lg font-medium leading-6 text-gray-900 sm:truncate">
        {{header}}
      </h1> 
      
      <div class="pb-1 pt-4 hidden">
        <Breadcrumbs/>
      </div>

    </div>
 
    <div class="flex mt-4 sm:mt-0 sm:ml-4"> 

      <span v-if="btnVisible === true" class="order-1 ml-3 rounded-md shadow-sm sm:order-0 sm:ml-0">
        <router-link
          :to="{ name: route }"
          type="button"
          class="inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 
          ease-in-out border border-transparent rounded-md bg-ha-rose-zero hover:bg-ha-rose-two focus:outline-none 
          focus:shadow-outline-ha-rose-one focus:border-ha-rose-zero active:bg-ha-rose-zero"
        >
          {{btn_Title}}
        </router-link>
      </span>

      <span class="rounded-md shadow-sm order-0 sm:order-1 sm:ml-3"> 
        <router-link
          to="/lease/create"
          type="button"
          class="inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 
          ease-in-out border border-transparent rounded-md bg-ha-green-one hover:bg-ha-green-two focus:outline-none 
          focus:shadow-outline-ha-rose-one focus:border-ha-green-one active:bg-ha-green-one"
        >
          Create Lease
        </router-link>
      </span>
 
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent, onMounted, reactive } from "vue";
import Breadcrumbs from "../components/Breadcrumbs.vue";

export default defineComponent({
  name: "Header",
  components: { Breadcrumbs },
  props: {
    header: String,
    btn_Title: String,
    route: String,
    btnVisible: Boolean
  }, 
 
});
</script>