<template>
  <tr>
    <td class="px-6 py-4 whitespace-nowrap">
      <div class="flex items-center">
        <div class="flex-shrink-0 h-10 w-10">
          
          <div v-if="contact.avatar === ''">
            <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-ha-green-one">
              <span class="text-xs font-medium leading-none text-white">TW</span>
            </span>
          </div>

          <div v-else> 
            <img class="h-10 w-10 rounded-full" :src="contact.avatar" alt="">
          </div>

        </div>
        <div class="ml-4">
          <div class="text-sm font-medium text-gray-900 truncate">
            {{contact.name}}
          </div>
          <div class="text-sm text-gray-500">
            <p class="mt-2 flex items-center text-sm text-gray-500">
              <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" x-description="Heroicon name: solid/mail" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
              </svg>
              <span class="truncate">{{contact.email}}</span>
            </p>            
          </div>
        </div>
      </div>
    </td>
    <td class="px-6 py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap">
      {{ contact.title }}
    </td> 
    <td class="px-6 py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap"> 
        <p class="mt-2 flex items-center text-sm text-gray-500">
          <i class="fad fa-phone flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400 text-xl"></i>
          <span class="truncate">{{contact.phone}}</span>
        </p>  
    </td> 
     
    <td
      class="px-6 py-4 text-sm font-medium leading-5 text-right whitespace-no-wrap"
    >
      <!-- <RouterLink
        :to="{ name: 'contact-edit', params: { id: contact.id, props: { showModal: true } } }"
        class="text-ha-blue-one hover:text-ha-blue-two"
      >
        <i class="fad fa-edit text-xl" title="Edit"></i> 
        
      </RouterLink> -->
          <button type="button" @click="toggleVisible(contact.id)" class="text-ha-blue-one hover:text-ha-blue-two" title="add new payment">
            <i class="fad fa-edit text-xl" title="Edit"></i> 
          </button> 
          
          
    </td>
  </tr>
 
  <ContactForm :showModal="showModal" @close="showModal = false" :id="id" />

</template>

<script lang="ts">

import { defineComponent, reactive, toRefs } from "vue";
import ContactForm from "./ContactForm.vue";

export default defineComponent({
  name: "ContactTableItem",
  components: {
    ContactForm
  },

  props: {
    contact: Object,
  },

  setup() {

    const state = reactive({
      showModal: false, 
      id: Number
    });

    function toggleVisible(id) {
      state.showModal = !state.showModal; 
      state.id = id;
    }

    return {
      ...toRefs(state),  
      toggleVisible,
    };

  }


});
</script>