<template>
  <div class="flex h-screen overflow-hidden bg-white">
    <MobileSidebar
      :isVisible="visibleMobileSidebar"
      :hideCallBack="toggleVisibleMobileSidebar"
    />
    <Sidebar />

    <div class="flex flex-col flex-1 w-0 overflow-hidden">
      <div
        class="relative z-10 flex flex-shrink-0 h-16 bg-white border-b border-gray-200 lg:hidden"
      >
        <button
          @click="toggleVisibleMobileSidebar"
          class="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:bg-gray-100 focus:text-gray-600 lg:hidden"
          aria-label="Open sidebar"
        >
          <svg
            class="w-6 h-6"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M4 6h16M4 12h8m-8 6h16" />
          </svg>
        </button>
        <div class="flex justify-between flex-1 px-4 sm:px-6 lg:px-8">
          <div class="flex flex-1">
            <form class="flex hidden w-full md:ml-0" action="#" method="GET">
              <label htmlFor="search_field" class="sr-only"> Search </label>
              <div
                class="relative w-full text-gray-400 focus-within:text-gray-600"
              >
                <div
                  class="absolute inset-y-0 left-0 flex items-center pointer-events-none"
                >
                  <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    />
                  </svg>
                </div>
                <input
                  id="search_field"
                  class="block w-full h-full py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 rounded-md focus:outline-none focus:placeholder-gray-400 sm:text-sm"
                  placeholder="Search"
                  type="search"
                />
              </div>
            </form>
          </div>
          <div class="flex items-center">
            <div class="relative ml-3">
              <div>
                <button
                  @click="toggleVisible"
                  @blur="toggleVisible"
                  class="flex items-center max-w-xs text-sm rounded-full focus:outline-none focus:shadow-outline"
                  id="user-menu"
                  aria-label="User menu"
                  aria-haspopup="true"
                >
                  <img
                    class="w-8 h-8 rounded-full"
                    src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </button>
              </div>
              <ContextMenu :lists="contextMenuItems" :isVisible="visible" />
            </div>
          </div>
        </div>
      </div>
      <main
        class="relative z-0 flex-1 overflow-y-auto focus:outline-none"
        tabIndex="0"
      >
       
      <router-view />
      </main>
    </div>
  </div>
</template>
 
<script lang="ts">

import { reactive, defineComponent, toRefs, onMounted, provide, onBeforeMount, } from "vue"; 
import Sidebar from "./components/Sidebar.vue";
import MobileSidebar from "./components/MobileSidebar.vue";
import ContextMenu from "./components/ContextMenu.vue";
import { getCurrentUser } from "./utils/fakeApi";

export default defineComponent({
  name: "App",
  components: { Sidebar, MobileSidebar, ContextMenu },

  setup() {

    const state = reactive({
      visibleMobileSidebar: false,
      visible: true,
      title: 'Dashboard',
      btnVisible: false,
      contextMenuItems: [
        [
          { title: "My Account", link: "/", subItems: [] },
          { title: "Settings", link: "/", subItems: [] },
          { title: "Refer a friend", link: "/", subItems: [] },
        ],
      ],
    });

    const toggleVisibleMobileSidebar = () => {
      state.visibleMobileSidebar = !state.visibleMobileSidebar;
    };

    const toggleVisible = () => {
      state.visible = !state.visible;
    };

    onBeforeMount(() => {
      const user = getCurrentUser();
      provide("user", user);
    });

    onMounted(() => {
      const script = document.createElement("script");
      script.src = "https://kit.fontawesome.com/74b0439164.js"; 
      //  script.src = "https://kit.fontawesome.com/033763eae2.js"; 
      script.crossOrigin = 'anonymous';
      script.async = true;
      document.head.appendChild(script);
    });

    return {
      toggleVisibleMobileSidebar,
      toggleVisible,
      ...toRefs(state),
    };
  },
});
</script>

