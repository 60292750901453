<template>
<Header :header="header" :route="route" :btn_Title="btn_Title" :btnVisible="btnVisible" />
 
<div class="px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
  <div class="flex items-center">
    <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
    <button type="button" class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 
    border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none 
    focus:ring-2 focus:ring-offset-2 focus:ring-ha-rose-two" role="switch" aria-checked="false"> 
      <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
      <span aria-hidden="true" class="translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow 
        transform ring-0 transition ease-in-out duration-200">
      </span>
    </button>
    <span class="ml-3" id="annual-billing-label">
      <span class="text-sm font-medium text-gray-900">Switch to Tenant </span> 
    </span>
  </div>
</div>

<div class="flex flex-col items-center p-6 lg:p-8">

  <div class="w-full max-w-screen-xl -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      
    <div v-if="tenantMaintenances.length > 0" class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">

    <div>
      <h3 class="text-lg font-medium leading-10 text-gray-900 hidden">
        Tenants Maintenance
      </h3>
      <!-- <p class="mt-1 text-sm leading-5 text-gray-500">[message]</p> -->
    </div>

      <div class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
        <TenantMaintenanceTable :maintenances="tenantMaintenances" />
      </div>
    </div>

    <div v-if="myMaintenances.length > 0" class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"> 
      <div>
        <h3 class="text-lg font-medium leading-10 text-gray-900 hidden">
          My Maintenance
        </h3>
        <!-- <p class="mt-1 text-sm leading-5 text-gray-500">[message]</p> -->
      </div>

      <div class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
        <MaintenanceTable :maintenances="myMaintenances" />
      </div>
    </div>

  </div>
</div>
</template>

<script lang="ts">
 
import { defineComponent, onMounted, reactive, toRefs } from "vue"; 
import MaintenanceTable from "../components/MaintenanceTable.vue"; 
import TenantMaintenanceTable from "../components/TenantMaintenanceTable.vue"; 
import { getMyMaintenances } from "../utils/fakeApi";
import { getTenantMaintenances } from "../utils/fakeApi";
import Header from "../components/Header.vue";

export default defineComponent({
  name: "FinancialIndex",
  components: {
    MaintenanceTable,
    TenantMaintenanceTable,
    Header

  },
  setup(){

    const state = reactive({
      myMaintenances: [],
      tenantMaintenances: [],
      header: 'Maintenance',
      route: 'maintenance-create',
      btn_Title: 'Create Maintenance',
      btnVisible: true
    });
 
    onMounted(() => { 

      state.myMaintenances = getMyMaintenances().map((myMaintenances, index) => {
        return Object.assign(myMaintenances, { id: index });
      }); 

      state.tenantMaintenances = getTenantMaintenances().map((tenantMaintenances, index) => {
        return Object.assign(tenantMaintenances, { id : index });
      });


    });

    return {
      ...toRefs(state), 
    };
  },

});
 
</script>