import { createWebHistory, createRouter } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import MaintenanceDetail from "@/views/MaintenanceDetail.vue";
import MaintenanceForm from "@/views/MaintenanceForm.vue"; 
import MaintenanceIndex from "@/views/MaintenanceIndex.vue"
import PropertyDetail from "@/views/PropertyDetail.vue";
import PropertyForm from "@/views/PropertyForm.vue";
import PropertyIndex from "@/views/PropertyIndex.vue"; 
import LeaseForm from "@/views/LeaseForm.vue";
import LeaseIndex from "@/views/LeaseIndex.vue"; 
import ContactIndex from "@/views/ContactIndex.vue";  
import ContactDetail from "@/views/ContactDetail.vue";
import FinancialIndex from "@/views/FinancialIndex.vue"; 

const routes = [
    {
        path: "/",
        name: "dashboard",
        component: Dashboard,
        meta: { title: "Dashboard" }
    },

    {
        path: "/maintenance/detail/:id",
        name: "maintenance-detail",
        component: MaintenanceDetail,
        meta: { title: "Maintenance Detail" }
    },

    {
        path: "/maintenance/edit/:id",
        name: "maintenance-edit",
        component: MaintenanceForm,
        meta: { title: "Maintenance Edit" }
    },

    {
        path: "/maintenance/create",
        name: "maintenance-create",
        component: MaintenanceForm,
        meta: { title: "Maintenance Create" }
    },

    {
        path: "/maintenance",
        name: "maintenance",
        component: MaintenanceIndex,
        meta: { title: "Maintenance" }
    },
 
    {
        path: "/property/:id",
        name: "property-detail",
        component: PropertyDetail,
        meta: { title: "Property Detail" }
    },

    {
        path: "/property/edit/:id",
        name: "property-edit",
        component: PropertyForm,
        meta: { title: "Property Edit" }
    },

    {
        path: "/property/create",
        name: "property-create",
        component: PropertyForm,
        meta: { title: "Property Create" }
    },

    {
        path: "/properties",
        name: "properties",
        component: PropertyIndex,
        meta: { title: "Properties" }
    },  

    {
        path: "/leases",
        name: "leases",
        component: LeaseIndex,
        meta: { title: "Leases" }
    },   

    {
        path: "/lease/edit/:id",
        name: "lease-edit",
        component: LeaseForm,
        meta: { title: "Lease Edit" }
    },
    
    {
        path: "/lease/create",
        name: "lease-create",
        component: LeaseForm,
        meta: { title: "Lease" }
    },

    {
        path: "/contact/detail/:id",
        name: "contact-detail",
        component: ContactDetail,
        meta: { title: "Contact Detail" }
    }, 

    {
        path: "/contacts",
        name: "contacts",
        component: ContactIndex,
        meta: { title: "Contacts" }
    },

    {
        path: "/financials",
        name: "financials",
        component: FinancialIndex,
        meta: { title: "Financial" }
    },
 
 
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;