<template>
  <tr>
    <td
      class="px-6 py-4 text-sm font-medium leading-5 text-gray-900 whitespace-no-wrap truncate"
    >
    <div class="flex items-center">
      <div class="flex-shrink-0 h-10 w-10">
        <img class="h-10 w-10 rounded-full" :src="maintenance.avatar" alt="">
      </div>
      <div class="ml-4">
        <div class="text-sm font-medium text-gray-900">
          {{ maintenance.lease }}
        </div>
        <div class="text-sm text-gray-500">
          {{ maintenance.email }}
        </div>
      </div>
    </div>
    </td>
    <td class="px-6 py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap">
      {{ maintenance.createDate }}
    </td>
    <td v-if="maintenance.status === 'New'" class="px-6 py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap"> 
      <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-400"> 
        {{ maintenance.status }}
      </span>  
    </td>
    <td v-else-if="maintenance.status === 'In Progress'" class="px-6 py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap"> 
      <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-400"> 
        {{ maintenance.status }}
      </span>   
    </td>
    <td v-else-if="maintenance.status === 'Resolved'" class="px-6 py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap">  
      <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-400"> 
        {{ maintenance.status }}
      </span>   
    </td>

    <td
      class="px-6 py-4 text-sm font-medium leading-5 text-right whitespace-no-wrap"
    >
      <RouterLink
        :to="{ name: 'maintenance-edit', params: { id: maintenance.id } }"
        class="text-ha-blue-one hover:text-ha-blue-two"
      >
        <i class="fad fa-edit text-xl" title="Edit"></i> 
      </RouterLink>
    </td>
  </tr>
</template>


<script lang="ts">
  
import { defineComponent } from "vue";
 
export default defineComponent({
  name: "MaintenanceTableItem",
  props: {
    maintenance: Object,
  },  

});

</script>