<template>
  <div
    :class="[
      isVisible ? 'lg:flex' : 'lg:hidden',
      'z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg hidden ',
    ]"
  >
    <div
      class="w-full bg-white rounded-md shadow-xs"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
    >
      <div class="py-1" v-for="(list, index) in lists" :key="index">
        <template v-for="(item, index) in list">
          <template v-if="item.subItems.length > 0"></template>
          <template v-else>
            <template v-if="index === list.length - 1">
              <ContextMenuItem
                :key="index"
                :title="item.title"
                link="item.link"
              />
              <div
                :key="'border-key-' + index"
                class="border-t border-gray-100"
              ></div>
            </template>
            <ContextMenuItem
              v-else
              :key="index"
              :title="item.title"
              :link="item.link"
            />
          </template>
        </template>
      </div>

      <div class="py-1">
        <a
          href="/"
          class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
          role="menuitem"
        >
          Logout
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ContextMenuItem from "./ContextMenuItem.vue";

export default defineComponent({
  name: "ContextMenu",
  components: { ContextMenuItem },
  props: {
    lists: Array,
    isVisible: Boolean,
  },
  setup() {},
});
</script>