<template>
  <Header
    :header="header"
    :route="route"
    :btn_Title="btn_Title"
    :btnVisible="btnVisible"
  />
  <div class="flex justify-center p-6 lg:p-8">
    <form class="w-11/12" :v-on="handleFormSubmission"> 
      <div class="flex flex-col w-full">
        <div>
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            {{
              propertyId
                ? `${property.address} ${property.city}, ${property.state} ${property.zip}`
                : "Create Lease"
            }}
          </h3>
          <!-- <p class="mt-1 text-sm leading-5 text-gray-500">[message]</p> -->
        </div>

        <!-- FORM FIELDS START -->
          <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
          
            <div class="sm:col-span-2">
              <label
                for="propertyType"
                class="block text-sm font-medium leading-5 text-gray-700"
              >
                Property Type
                <i class="fas fa-asterisk required" aria-required="true"></i>
              </label>
              <div class="mt-1 rounded-md shadow-sm">
                <select
                  id="propertyType" 
                  v-model="property.propertyType"
                  required 
                  onChange="" 
                  type="select"
                  class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm 
                  focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"
                >
                  <option value=""></option>
                  <option value="MA">Residensial</option>
                  <option value="NY">Student</option>
                  <option value="RI">Comercial</option>
                </select>
              </div>
            </div>
            
            <div class="sm:col-span-4">
              <label
                for="address"
                class="block text-sm font-medium leading-5 text-gray-700"
              >
                Address
                <i class="fas fa-asterisk required" aria-required="true"></i>
              </label>
              <div class="mt-1 rounded-md shadow-sm">
                <input
                  id="address"
                  type="text"
                  required 
                  autoComplete="street-address"
                  v-model="property.address"
                  onChange=""
                  class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm 
                  focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"
                />
              </div>
            </div>
            
            <div class="sm:col-span-2">
              <label
                for="city"
                class="block text-sm font-medium leading-5 text-gray-700"
              >
                City
                <i class="fas fa-asterisk required" aria-required="true"></i>
              </label>
              <div class="mt-1 rounded-md shadow-sm">
                <input
                  id="city"
                  type="text"
                  required 
                  v-model="property.city"
                  onChange="" 
                  class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm 
                  focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"

                />
              </div>
            </div>
            			   
            <div class="sm:col-span-3">
              <label
                for="state"
                class="block text-sm font-medium leading-5 text-gray-700"
              >
                State
                <i class="fas fa-asterisk required" aria-required="true"></i>
              </label>
              <div class="mt-1 rounded-md shadow-sm">
                <select
                  id="state"
                  type="text"
                  required 
                  v-model="property.state"
                  onChange="" 
                  class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm 
                  focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"
                >
                  <option value=""></option>
                  <option value="MA">Massachussetts</option>
                  <option value="NY">New York</option>
                  <option value="RI">Rhode Island</option>
                </select>
              </div>
            </div>
            
            <div class="sm:col-span-1">

              <label
                for="zip"
                class="block text-sm font-medium leading-5 text-gray-700"
              >
                Zip
                <i class="fas fa-asterisk required" aria-required="true"></i>
              </label>
              <div class="mt-1 rounded-md shadow-sm">
                <input
                  id="zip"
                  type="text"
                  autoComplete="postal-code"
                  required 
                  v-model="property.zip"
                  onChange="" 
                  class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm 
                  focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"
                />
              </div>
            </div>
            
            <div class="sm:col-span-2">
              <label
                for="owner"
                class="block text-sm font-medium leading-5 text-gray-700"
              >
                Owner
                <i class="fas fa-asterisk required" aria-required="true"></i>
              </label>
              <div class="mt-1 rounded-md shadow-sm">
                <input
                  id="owner"
                  type="text"
                  autoComplete="name"
                  required 
                  v-model="property.owner"
                  onChange="" 
                  class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm 
                  focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"
                />
              </div>
            </div>
            
            <div class="sm:col-span-1">
              <label
                for="phoneNumber"
                class="block text-sm font-medium leading-5 text-gray-700"
              >
                Phone
              </label>
              <div class="mt-1 rounded-md shadow-sm">
                <input
                  id="phoneNumber"
                  type="text"
                  autoComplete="tel"
                  v-model="property.phoneNumber"
                  onChange="" 
                  class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm 
                  focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"
                />
              </div>
            </div>
            
            <div class="sm:col-span-2">

              <label
                for="email"
                class="block text-sm font-medium leading-5 text-gray-700"
              >
                Email
                <i class="fas fa-asterisk required" aria-required="true"></i>
              </label>  
              <div class="mt-1 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <!-- Heroicon name: solid/mail -->
                  <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                  </svg>
                </div>
                <input 
                  id="email"
                  type="text"
                  autoComplete="email"
                  required 
                  v-model="property.email"
                    class="block w-full pl-10  border-gray-300 rounded-md shadow-sm 
                  focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm" placeholder="you@example.com">
              </div>

            </div>
                          
            <div class="sm:col-span-1"> 
              <label
                for="mortgagePayment"
                class="block text-sm font-medium leading-5 text-gray-700"
              >
                Mortgage payment
                <i class="fas fa-asterisk required" aria-required="true"></i>
              </label> 
              <div class="mt-1 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span class="text-gray-500 sm:text-sm font-bold">
                    $
                  </span>
                </div>
                <input 
                  type="text" 
                  id="mortgagePayment" 
                  required 
                  v-model="property.mortgagePayment"
                  class="box-border block w-full pl-7 pr-12 sm:text-sm  border-gray-300 rounded-md shadow-sm 
                  focus:ring-ha-rose-one focus:border-ha-rose-one" 
                  placeholder="0.00" 
                  aria-describedby="price-currency">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span class="text-gray-500 sm:text-sm" id="price-currency">
                    USD
                  </span>
                </div>
              </div>
            </div>     

            <div class="sm:col-span-1">

              <label
                for="purchaseDate"
                class="block text-sm font-medium leading-5 text-gray-700"
              >
                Purchased Date
                <i class="fas fa-asterisk required" aria-required="true"></i>
              </label>
              <div class="mt-1 rounded-md shadow-sm">
                <input
                  id="purchaseDate"
                  type="date"
                  required 
                  v-model="property.purchaseDate"
                  onChange="" 
                  class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm 
                  focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"
                />
              </div> 
            </div>     
            
            <div class="sm:col-span-1"> 
              <label
                for="purchasePrice"
                class="block text-sm font-medium leading-5 text-gray-700"
              >
                Purchased Price
              </label> 
              <div class="mt-1 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span class="text-gray-500 sm:text-sm font-bold">
                    $
                  </span>
                </div>
                <input 
                  id="purchasePrice"
                  type="text"
                  v-model="property.purchasePrice"
                  class="box-border block w-full pl-7 pr-12 sm:text-sm  border-gray-300 rounded-md shadow-sm 
                  focus:ring-ha-rose-one focus:border-ha-rose-one" 
                  placeholder="0.00" 
                  aria-describedby="price-currency">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span class="text-gray-500 sm:text-sm" id="price-currency">
                    USD
                  </span>
                </div>
              </div>
            </div> 
          
            <div class="sm:col-span-1">

              <label
                for="sellDate"
                class="block text-sm font-medium leading-5 text-gray-700"
              >
                Sell Date
              </label>
              <div class="mt-1 rounded-md shadow-sm">
                <input
                  id="sellDate"
                  type="date"
                  v-model="property.sellDate"
                  onChange="" 
                  class="box-border block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm 
                  focus:ring-ha-rose-one focus:border-ha-rose-one sm:text-sm"
                />
              </div> 
            </div> 
            
            <div class="sm:col-span-1"> 
              <label
                for="sellPrice"
                class="block text-sm font-medium leading-5 text-gray-700"
              >
                Sell Price
              </label> 
              <div class="mt-1 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span class="text-gray-500 sm:text-sm font-bold">
                    $
                  </span>
                </div>
                <input 
                  id="sellPrice"
                  type="text"
                  v-model="property.sellPrice"
                  class="box-border block w-full pl-7 pr-12 sm:text-sm  border-gray-300 rounded-md shadow-sm 
                  focus:ring-ha-rose-one focus:border-ha-rose-one" 
                  placeholder="0.00" 
                  aria-describedby="price-currency">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span class="text-gray-500 sm:text-sm" id="price-currency">
                    USD
                  </span>
                </div>
              </div>

            </div>     

          </div> 
        <!-- FORM FIELDS END -->
      </div> 
      <div class="pt-5 mt-8 border-t border-gray-200">
        <div class="flex justify-end">
          <span class="inline-flex rounded-md shadow-sm">
            <a
              class="px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out border
               border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
              href="/properties"
            >
              Cancel
            </a>
          </span>
          <span class="inline-flex ml-3 rounded-md shadow-sm">
            <button
              type="submit"
              v-if="propertyId"
              class="inline-flex justify-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out
              bg-ha-rose-zero border border-transparent rounded-md hover:bg-ha-rose-two focus:outline-none focus:border-ha-rose-zero 
              focus:shadow-outline-ha-rose-one active:bg-ha-rose-zero"
            >
              Update
            </button>
            <button
              v-else
              type="submit"
              class="inline-flex justify-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out
              bg-ha-rose-zero border border-transparent rounded-md hover:bg-ha-rose-two focus:outline-none focus:border-ha-rose-zero 
              focus:shadow-outline-ha-rose-one active:bg-ha-rose-zero"
            >
              Save
            </button>
          </span>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, toRefs, watch } from "vue";
import { useRoute } from "vue-router";
import { getProperties } from "../utils/fakeApi";
import Axios from "../axios";
import Header from "../components/Header.vue";


export default defineComponent({
  name: "PropertyForm",

  components: {
    Header
  },

  setup() {
    const route = useRoute();

    const state = reactive({
      header: "Lease",
      route: "",
      btn_Title: "",
      btnVisible: false,
      response: null,
      stateOptions: { NY: "New York", MA: "Massachussets", RI: "Rhode Island" },
      typeOptions: [],
      propertyId: null,
      property: {
        address: "",
        state: "",
        city: "",
        type: "",
        zip: "",
        owner: "",
        email: "",
        phoneNumber: "",
        buildDate: "",
        mortgagePayment: "",
        purchaseDate: "",
        purchasePrice: "",
        sellPrice: "",
        sellDate: "",
        income: 0,
        expenses: 0,
        note: "",
      },
    });

    onMounted(() => {
      const properties = getProperties();
      state.propertyId = route.params.id;
      if (
        !Number.isInteger(state.propertyId) &&
        state.propertyId < properties.length
      ) {
        state.property = properties[state.propertyId];
      }
    });

    watch(
      () => route.name,
      () => {
        for (let key in state.property) {
          state.property[key] = "";
        }
        state.propertyId = null;
      }
    );

    const handleFormSubmission = async () => {
      state.response = await Axios({
        method: "post",
        url: `/properties/create?property${state}`,
      });
    };

    return {
      ...toRefs(state),
      handleFormSubmission,
    };
  },
});
</script>