<template> 

  <li>
    <a href="#" class="block hover:bg-gray-50">
        <div class="flex items-center px-4 py-4 sm:px-6">
            <div class="min-w-0 flex-1 flex items-center">
                
                <div class="min-w-0 flex-1 md:grid md:grid-cols-3 md:gap-4">
                    <div>
                        <p class="text-sm font-medium text-gray-500 truncate">{{latestTransactions.createDate}}</p>  

                        <div v-if="latestTransactions.type === 'Expense'">
                          <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-400">                         
                            {{latestTransactions.type}}
                          </span>
                        </div> 

                        <div v-else>
                          <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-400"> 
                            {{latestTransactions.type}}
                          </span>
                        </div>  

                    </div>

                    <div class="hidden md:block">
                        <div>
                            <p class="text-sm text-gray-900 truncate"> 
                                {{latestTransactions.payer}}
                            </p> 
                            <p class="mt-2 flex items-center text-sm text-gray-500"> 
                                <span class="truncate text-gray-400">{{latestTransactions.category}}</span>
                            </p> 
                        </div>
                    </div>

                    <div> 
                        <p class="-mt-10 sm:mt-2 float-right pr-4 text-base text-gray-500 truncate"> 
                            <span v-if="latestTransactions.type === 'Expense'" class="text-gray-400">-$ {{latestTransactions.amount}}</span>
                            <span v-else class="text-gray-400">${{latestTransactions.amount}}</span>
                        </p>  
                    </div> 

                </div>

                <div>  
                  <RouterLink
                    :to="{ name: 'financials', params: { id: latestTransactions.id } }"
                    class="text-ha-blue-one hover:text-ha-blue-two"
                  >
                    <i class="flex-2 fad fa-angle-right text-ha-blue-one text-xl"></i>
                  </RouterLink>
                </div>

            </div>

        </div>
    </a>
  </li>
  
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "WidgetsFanancialItems",
  props: {
    latestTransactions: Object,
  },
});
</script>