<template>
<Header :header="header" :route="route" :btn_Title="btn_Title" :btnVisible="btnVisible" />

  <div class="flex flex-col items-center p-6 lg:p-8">
    <div class="w-full max-w-screen-xl -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div
          class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg"
        >
          <PropertyTable :properties="properties" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent, onMounted, reactive, toRefs } from "vue"; 
import PropertyTable from "../components/PropertyTable.vue"; 
import { getProperties } from "../utils/fakeApi";
import Header from "../components/Header.vue";

export default defineComponent({

  name: "PropertyIndex",

  components: {
    PropertyTable,
    Header,
  },

  setup() {
    const state = reactive({
      properties: [],
      header: 'Properties',
      route: 'property-create',
      btn_Title: 'Create Property',
      btnVisible: true

    });

    onMounted(() => {
      state.properties = getProperties().map((property, index) => {
        return Object.assign(property, { id: index });
      });
    });

    return {
      ...toRefs(state),
    };
  },
});
</script>