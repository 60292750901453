import properties from "../mock/property.json";
import tenantMaintenance from "../mock/tenantMaintenance.json";
import myMaintenance from "../mock/myMaintenance.json";
import user from "../mock/currentUser.json";
import maintenancesCharts from "../mock/maintenanceChart.json";
import leases from "../mock/leases.json";
import contacts from "../mock/contacts.json";
import latestTransaction from "../mock/latestTransaction.json";


export function getCurrentUser() {
    return user ?? {
        name: "Demo",
        email: "demo@homesapp.com",
        avatar_url:
          "https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80",
      };
}

export function getProperties() {
    return properties ?? [];
}

export function getProperty(id) {
    return properties[id] ?? {};
}

export function getLeases() {
    return leases ?? [];
}

export function getLease(id) {
    return leases[id] ?? {};
}

export function getMyMaintenances(){
    return myMaintenance ?? [];
}

export function getTenantMaintenances(){
    return tenantMaintenance ?? [];
}

export function getMyMaintenance(id) {
    return myMaintenance[id] ?? {};
}

export function getTenantMaintenance(id) {
    return tenantMaintenance[id] ?? {};
}

export function getMaintenancesCharts() {
    return maintenancesCharts ?? [];
}

export function getContact(id) {
    return contacts[id] ?? {};
}

export function getContacts() {
    return contacts ?? [];
}

export function getLatestTransaction(id) {
    return latestTransaction[id] ?? {};
}

export function getLatestTransactions() {
    return latestTransaction ?? [];
}